import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'

export default class DrivingTestsRoute extends Route {
  @service
  studentEvents

  async model() {
    const product = 're'
    const { uiScope: scope, statsScope, period, scopedData } = this.modelFor('reporting.ui-scope')

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product, scope, period) {
    const { studentEvents } = this

    return RSVP.hash({
      drivingTestsTotals: studentEvents.drivingTestsTotalsByGradeByMonth(product, scope, period),
    })
  }
}
