import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the assignment_scores endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} assignmentScope - The assignmentScope for this query
 * @param {String} assignmentScope.scope - The scope of the records to query, e.g. assignments
 * @param {Array} assignmentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function assignmentScores(product, assignmentScope, period) {
  if (isEmpty(assignmentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('assignment_scores', product, assignmentScope)
  const query = this.buildQuery(assignmentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { assignment_scores: scores } = data

    if (!scores || scores.length === 0) {
      return null
    }

    return data
  })
}
