import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import colors from '@blakeelearning/blake-colours/colours'

const colorMap = {
  re: {
    below: colors.oceanyBlue100,
    at: colors.oceanyBlue300,
    above: colors.oceanyBlue400,
  },
  rex: {
    below: colors.oceanyBlue100,
    at: colors.oceanyBlue300,
    above: colors.oceanyBlue400,
  },
  maths: {
    below: colors.msGreen150,
    at: colors.msGreen300,
    above: colors.msGreen400,
  },
}

/**
 * Puts together all the pieces for the working at grade level totals chart.
 *
 * @class WorkingAtGradeLevelTotals
 *
 * Data format:
 *
 * ```
 * {
 *   working_at_grade_level_totals_by_grade: [
 *    {
 *      grade_position: 1,
 *      student_count: 40,
 *      below: 384,
 *      at: 501,
 *      above: 217,
 *    },
 *    {
 *      grade_position: 2,
 *      student_count: 40,
 *      below: 384,
 *      at: 501,
 *      above: 217,
 *    },
 *   ],
 * }
 * ```
 *
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.working_at_grade_level_totals_by_grade - The data object namespace
 * @property {Number} data.working_at_grade_level_totals_by_grade.@each.grade_position - The grade number the data represents
 * @property {Number} data.working_at_grade_level_totals_by_grade.@each.student_count - The number of students the data represents
 * @property {Number} data.working_at_grade_level_totals_by_grade.@each.below - The count of students working *below* grade level
 * @property {Number} data.working_at_grade_level_totals_by_grade.@each.at - The count of students working *at* grade level
 * @property {Number} data.working_at_grade_level_totals_by_grade.@each.above - The count of students working *above* grade level
 */
export default class ChartsWorkingAtGradeLevelTotals extends Component {
  @service intl

  get colours() {
    return colorMap[this.args.product]
  }

  /**
   * The input data for the chart, formatted for Highcharts.
   * Includes the colours to lock highcharts in to using those specific colours
   */
  get chartData() {
    const { below, at, above } = this.args.data
    const { below: belowColor, at: atColor, above: aboveColor } = this.colours

    return [
      { name: 'Below', data: [{ y: below, color: belowColor }] },
      { name: 'On', data: [{ y: at, color: atColor }] },
      { name: 'Above', data: [{ y: above, color: aboveColor }] },
    ]
  }

  get yAxis() {
    const { below, at, above } = this.args.data
    const max = below + at + above

    return {
      startOnTick: false,
      endOnTick: false,
      gridLineWidth: 0,
      max,
      labels: { enabled: false },
      title: { text: null },
      reversedStacks: false,
    }
  }

  /**
   * Data that will be provided to the legend for labels
   */
  get legendData() {
    const gradeData = this.args.data
    const categories = [
      { key: 'below', label: 'Below' },
      { key: 'at', label: 'On' },
      { key: 'above', label: 'Above' },
    ]
    return categories.map(({ label, key }) => {
      const colour = this.colours[key]
      const data = gradeData[key]
      return { label, colour, data }
    })
  }

  /**
   * Number of contributing students for left side of chart
   * converted to string if >= 10000, eg 10k
   */
  get contributingStudents() {
    const studentCount = this.args.data?.student_count ?? 0
    const countToStartRounding = 10000
    if (studentCount >= countToStartRounding) {
      return formatNumber(studentCount, 1)
    }
    return studentCount
  }
}
