import { classNames } from '@ember-decorators/component'
import { service } from '@ember/service'
import { equal, sort, alias } from '@ember/object/computed'
import { A } from '@ember/array'
import Component from '@ember/component'
import { computed } from '@ember/object'
import isFinite from 'lodash/isFinite'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'
import { getColour } from 'district-ui-client/utils/giraffe/colour-picker'
import { tracked } from '@glimmer/tracking'

/**
 * Display a chart of lexile growth by grade.
 *
 * @class LexileGrowthComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object[]} data.lexile_growth_by_grade.grades - List of lexile growth objects, one for each grade
 * @property {Number} data.lexile_growth_by_grade.grades[].grade_position - The position number for the grade
 * @property {Number} data.lexile_growth_by_grade.grades[].from_lexile_growth - The initial lexile value
 * @property {Number} data.lexile_growth_by_grade.grades[].to_lexile_growth - The final lexile value
 * @property {Number} data.lexile_growth_by_grade.grades[].student_count - The number of students
 * @property {Number} data.lexile_growth_by_grade.student_count - The count of students that this data set is for
 */
@classNames('h-full', 'giraffe-dashboard-tile', 'giraffe-dashboard-lexile-growth')
export default class LexileGrowth extends Component {
  @service gradeSets

  @service intl

  /**
   * The product the chart data represents (controls theme colours)
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  product = null

  /**
   * The type of the chart (controls theme colours)
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  type = 'lexileGrade'

  /**
   * The title of the chart
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  title = 'Lexile Growth'

  /**
   * Computed list of grades, extracted from the input data.
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  @alias('data.lexile_growth_by_grade.grades') grades

  /**
   * Name of the property on each grade object to sort the grades by.
   *
   * @private
   * @instance
   * @memberOf LexileGrowthComponent
   */
  @tracked _sortProperties = ['grade_position']

  /**
   * Sorted list of grades, extracted from the input data.
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  @sort('grades', '_sortProperties') sortedGrades

  /**
   * The translation for grade to display.
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  @computed('intl')
  get gradeLabel() {
    return this.intl.t('grade.label')
  }

  @computed('product', 'type')
  get colour() {
    const { product } = this
    const { type } = this
    const key = `${product}.${type}`
    return getColour(key)
  }

  /**
   * The input data for the chart, formatted for Highcharts.
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  @computed('colour', 'sortedGrades')
  get chartData() {
    const color = this.colour
    const data = this.sortedGrades.map((gradeInfo) => {
      const { from_lexile_growth: lexileFrom, to_lexile_growth: lexileTo, student_count: studentCount } = gradeInfo

      if ([studentCount, lexileFrom, lexileTo].every(isFinite)) {
        const avgMinlexile = Math.round(lexileFrom)
        const avgMaxlexile = Math.round(lexileTo)

        return [avgMinlexile, avgMaxlexile]
      }
      return [0, 0]
    })

    return [{ data, color }]
  }

  @computed('singleGrade')
  get subtitleKey() {
    const { singleGrade } = this
    if (singleGrade) return 'reporting.dashboardSubtitles.singleGrade.lexileGrowth'
    return 'reporting.dashboardSubtitles.multiGrade'
  }

  @equal('data.lexile_growth_by_grade.grades.length', 1) singleGrade

  /**
   * The series labels for the chart, formatted for Highcharts.
   * A list of formatted grade names.
   *
   * @instance
   * @memberOf LexileGrowthComponent
   */
  @computed('sortedGrades.[]')
  get categories() {
    const { sortedGrades } = this

    return A(sortedGrades.map((grade) => this.gradeSets.findByPosition(grade.grade_position)?.name ?? '-'))
  }

  @computed('data.lexile_growth_by_grade.student_count')
  get studentCount() {
    const count = this.data?.lexile_growth_by_grade?.student_count
    return formatNumber(count, 2)
  }

  get toolTip() {
    return toolTipConfig('y', null, {
      formatter() {
        if (this.point.low === this.point.high) {
          return `<b>${this.point.high}L</b>`
        }
        return `<b>From ${this.point.low}L to ${this.point.high}L</b>`
      },
    })
  }

  /**
   * Returns a useful datalabel string, if the point is a high point. If low point, returns an empty string.
   */
  dataLabelsFormatter() {
    const growth = this.point.high - this.point.low

    /*
     * First set of points run through the formatter are the high points, so if dataLabelUpper is not yet set,
     * return a useful datalabel
     *
     * Normally you could determine the high point with something like
     *    const isHighPoint = this.y === this.point.high
     * However this does not work when there is zero growth (ie this.point.high === this.point.low)
     */
    const isUpperLabel = !this.point.dataLabelUpper
    return isUpperLabel ? `+${growth}` : '' // ensure the lower label does not show
  }
}
