import Route from '@ember/routing/route'
import type DateFilterService from '@blakeelearning/dates/services/date-filter'
import type Store from '@ember-data/store'
import type RouterService from '@ember/routing/router-service'
import type Transition from '@ember/routing/transition'
import { service } from '@ember/service'
import type ReportingUiScopeController from 'district-ui-client/controllers/reporting/ui-scope'
import type SubscriptionTypeRoute from 'district-ui-client/routes/subscription-type'
import type SessionService from 'district-ui-client/services/session'
import { UIScope, validUiScopesFor } from 'district-ui-client/domain/ui-scope'
import { isMultiSchoolReport, isReportPath } from 'district-ui-client/services/report-registry'
import type District from 'district-ui-client/models/district'
import type School from 'district-ui-client/models/school'

export const defaultDateRange = 'named-period:this-year'
export const defaultLegacyDateRange = 'named-period:this-month'

function schoolInScope(schoolId: string, uiScope: UIScope, validScopes: UIScope[]): boolean {
  if (uiScope.scope === 'district') {
    // either school is present in districts' subscopes, or if displaying whole district, school is within valid scopes
    // valid scopes take into account schools' subscription
    if (uiScope.subScopes.length) {
      return uiScope.subScopes.some(({ scope, id }) => scope === 'school' && id === schoolId)
    }
    return validScopes.some(({ scope, id }) => scope === 'school' && id === schoolId)
  }
  return uiScope.scope === 'school' && uiScope.id === schoolId
}

interface Params extends Record<string, unknown> {
  // dynamic segments in url
  ui_scope_name: UIScope['scope']
  ui_scope_id: UIScope['id']
  // query param in url
  schoolIds?: string[]
}

export class UiScopeRoute extends Route {
  @service router!: RouterService

  @service dateFilter!: DateFilterService

  @service store!: Store

  @service session!: SessionService

  queryParams = {
    dateRange: {
      refreshModel: true,
      replace: true,
    },
    schoolIds: {
      refreshModel: true,
      replace: true,
    },
  }

  beforeModel(transition: Transition): void {
    // set the dateFilter service dateRange based on the transition, so that it's available for the model
    // the controller (& transition) will be updated in setupController. Doing a transition here seems to cause bugs.
    const dateRange = transition.to?.queryParams.dateRange
    if (dateRange && typeof dateRange === 'string') {
      this.dateFilter.setDateRange(dateRange)
    } else if (isReportPath(transition.to?.name) && isMultiSchoolReport(transition.to.name)) {
      this.dateFilter.setDateRange(defaultDateRange)
    } else {
      this.dateFilter.setDateRange(defaultLegacyDateRange)
    }

    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const {
      ui_scope_id: uiScopeId,
      ui_scope_name: uiScopeName,
      schoolIds,
    } = this.paramsFor('reporting.ui-scope') as Params

    // Determine the valid scopes that can be visited, and check the one we're visiting is valid
    const validUiScopes = validUiScopesFor(
      this.session.currentDistrict,
      this.store.peekAll('school').slice(),
      subscriptionType,
    )
    const isValidScope = validUiScopes.some(({ scope, id }) => scope === uiScopeName && id === uiScopeId)
    const isValidSubscope =
      !schoolIds?.length ||
      (uiScopeName === 'district' &&
        schoolIds.every((schoolId) => validUiScopes.some(({ scope, id }) => scope === 'school' && id === schoolId)))

    if (!isValidScope || !isValidSubscope) {
      // ember 4.12 routing bug causes the transition to 'subscription-type.error404' to fail here. This will do for now
      void this.router.replaceWith(`/${subscriptionType}/error404`)
      return
    }
  }

  model(params: Params) {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const { ui_scope_name: uiScopeName, ui_scope_id: uiScopeId, schoolIds } = params

    const uiSubScopes = schoolIds?.map((schoolId) => new UIScope('school', schoolId)) ?? []

    const uiScope = new UIScope(uiScopeName, uiScopeId, uiSubScopes)

    let districts: District[] = []
    let schools: School[] = []
    if (uiScope.scope === 'district') {
      const peekedRecord = this.store.peekRecord('district', uiScope.id)
      districts = peekedRecord ? [peekedRecord] : []

      const allSchools: School[] = this.store.peekAll('school').slice()
      const validUiScopes = validUiScopesFor(this.session.currentDistrict, allSchools, subscriptionType)
      schools = allSchools.filter((school) => schoolInScope(school.id, uiScope, validUiScopes))
    } else if (uiScope.scope === 'school') {
      districts = []
      const peekedRecord = this.store.peekRecord('school', uiScope.id)
      schools = peekedRecord ? [peekedRecord] : []
    }

    const scopedData = { districts, schools }

    return {
      statsScope: { scope: uiScopeName, ids: [parseInt(uiScopeId, 10)] },
      uiScope,
      period: this.dateFilter.period,
      scopedData,
    }
  }

  setupController(controller: ReportingUiScopeController, model: unknown, transition: Transition) {
    super.setupController(controller, model, transition)
    controller.drawer = true

    controller.dateRange = this.dateFilter.dateRange
  }
}

export default UiScopeRoute
