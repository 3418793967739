import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const Lozenge: TOC<Signature> = template(`
  <div
    class="inline-block rounded bg-pink-50 px-1.5 py-0.5 text-xs font-semibold uppercase text-pink-300"
    ...attributes
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
