import Component from '@ember/component'
import { A } from '@ember/array'
import { alias } from '@ember/object/computed'
import { computed } from '@ember/object'
import { service } from '@ember/service'
import BlakeColours from '@blakeelearning/blake-colours/colours'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import { formatNumber, formatFixedDecimal } from 'district-ui-client/utils/giraffe/formatter'
import { formattedDuration } from 'district-ui-client/utils/format-duration'
import { roundAccurately } from 'district-ui-client/utils/round'

const secondsInAnHour = 60 * 60

/**
 * Display a chart of time on task usage
 *
 * @class TimeOnTaskUsageComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.summary - The summary data for the time period
 * @property {Object} data.summary.student_count - The number of contributing students
 * @property {Object} data.summary.total_seconds - The total number of seconds usage
 * @property {Object} data.summary.average_seconds_per_student - The average seconds per student
 * @property {Object} data.summary.average_seconds_per_student_per_week - The average seconds per student per week
 * @property {Object} data.summary.session_count - The total number of sessions
 * @property {Object} data.summary.average_sessions_per_student - The average number of sessions per student
 * @property {Object} data.summary.average_seconds_per_session - The average number of seconds per session
 * @property {Object[]} data.months - The data for each month
 * @property {String} data.months[].month - The month this data represents
 * @property {Number} data.months[].seconds - The number of seconds usage this month
 * @property {String} scopeName - The scope of the current view (eg. "Class", "School", "District")
 * @property {String} activeScopeName - The name of the current scope (eg. class name, school name)
 */
export default class TimeOnTaskUsageChartComponent extends Component {
  @service intl

  tagName = ''

  @alias('data.summary.student_count') contributingStudents

  @computed('activeScopeName', 'data.summary', 'scopeName', 'mainTheme')
  get totalsStats() {
    const { intl, scopeName, activeScopeName, mainTheme } = this
    const siteCopyStatBoxPrefix = 'reporting.statBoxes.timeOnTaskUsage'
    const summaryData = this.data.summary

    const theme = mainTheme || 'default'

    return {
      data: [
        {
          label: `${scopeName} ${intl.t(`${siteCopyStatBoxPrefix}.totalHours`)}`,
          value: formatNumber(summaryData.total_seconds / secondsInAnHour, 1),
        },
        {
          label: intl.t(`${siteCopyStatBoxPrefix}.avgHoursPerStudent`),
          value: formatFixedDecimal(summaryData.average_seconds_per_student / secondsInAnHour, 1),
        },
        {
          label: intl.t(`${siteCopyStatBoxPrefix}.avgHoursPerStudentPerWeek`),
          value: formatFixedDecimal(summaryData.average_seconds_per_student_per_week / secondsInAnHour, 1),
        },
      ],
      title: `${activeScopeName} ${intl.t('reporting.chartTitles.timeOnTaskTotals')}`,
      theme,
    }
  }

  @computed('data.summary', 'scopeName')
  get sessionsStats() {
    const { intl } = this
    const siteCopyStatBoxPrefix = 'reporting.statBoxes.timeOnTaskUsage'
    const summaryData = this.data.summary

    return {
      data: [
        {
          label: `${this.scopeName} ${intl.t(`${siteCopyStatBoxPrefix}.totalSessions`)}`,
          value: formatNumber(summaryData.session_count, 1),
        },
        {
          label: intl.t(`${siteCopyStatBoxPrefix}.avgSessionsPerStudent`),
          value: formatNumber(summaryData.average_sessions_per_student, 1),
        },
        {
          label: intl.t(`${siteCopyStatBoxPrefix}.avgTimeInSession`),
          value: formattedDuration(summaryData.average_seconds_per_session),
        },
      ],
      title: intl.t('reporting.chartTitles.timeOnTaskSessions'),
      theme: 'orange',
    }
  }

  @computed('data.months.[]', 'y', 'mainTheme')
  get columnChart() {
    const { intl, mainTheme } = this
    const monthData = A(this.data.months)

    const color =
      {
        blue: BlakeColours.oceanyBlue300,
        green: BlakeColours.grapeyGreen300,
      }[mainTheme] || BlakeColours.dustyBlack100

    const hours = this.intl.t('hours')

    return {
      data: [
        {
          name: 'hours',
          data: monthData.map((md) => roundAccurately((md.seconds ?? 0) / secondsInAnHour, 1)),
          color,
        },
      ],

      titleOptions: {
        align: 'left',
        text: intl.t('reporting.chartTitles.timeOnTaskUsage'),
      },

      yAxisLabel: intl.t('reporting.yAxisLabels.timeOnTaskUsage'),

      categories: monthData.map((md) => convertDateFormat(md.month, 'yyyy-MM', 'MMMM')),

      toolTipFormatter() {
        return `${this.y} ${hours}`
      },
    }
  }
}
