import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'
import groupByGradePosition from '../../utils/group-by-grade-position'

/**
 * Fetches the books_read_by_genre_totals_by_grade_by_month endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function booksReadByGenreTotals(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl(
    'books_read_by_genre_totals_by_grade_by_month',
    product,
    studentScope,
  )
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const summaryKey = 'books_read_by_genre_totals_summary_by_grade'
    const dataKey = 'books_read_by_genre_totals_by_grade_by_month'
    const { [summaryKey]: summary } = data

    if (summary.length === 0) {
      return null
    }

    const byGrade = groupByGradePosition(data, summaryKey, dataKey)

    return {
      books_read_by_genre_totals: byGrade.map((grade) => ({
        ...grade,
        months: fillMonths(grade.months, period, {
          fiction: null,
          nonfiction: null,
        }),
      })),
    }
  })
}
