import { classNames } from '@ember-decorators/component'
import { alias } from '@ember/object/computed'
import Component from '@ember/component'
import { computed } from '@ember/object'
import colourPicker from 'district-ui-client/utils/giraffe/colour-picker'

/**
 * Display a chart of students working at grade level
 *
 * @class WorkingAtGradeLevelComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.working_at_grade_level - The data object namespace
 * @property {Number} data.working_at_grade_level.below - The count of students working *below* grade level
 * @property {Number} data.working_at_grade_level.at - The count of students working *at* grade level
 * @property {Number} data.working_at_grade_level.above - The count of students working *above* grade level
 */
@classNames('h-full', 'giraffe-dashboard-tile')
export default class WorkingAtGradeLevel extends Component {
  /**
   * The working at grade level data
   *
   * @instance
   * @memberOf WorkingAtGradeLevelComponent
   */
  @alias('data.working_at_grade_level') workingAtGradeLevelData

  /**
   * The input data for the chart, formatted for Highcharts.
   * Includes the colours to lock highcharts in to using those specific colours
   *
   * @instance
   * @memberOf WorkingAtGradeLevelComponent
   */
  @computed('workingAtGradeLevelData', 'colours')
  get chartData() {
    const { below, at, above } = this.workingAtGradeLevelData
    const { below: belowColor, at: atColor, above: aboveColor } = this.colours

    return [
      { name: 'Below', data: [{ y: below, color: belowColor }] },
      { name: 'On', data: [{ y: at, color: atColor }] },
      { name: 'Above', data: [{ y: above, color: aboveColor }] },
    ]
  }

  /**
   * The upper value of the highcharts chart. This makes sure the
   * chart values are properly aligned.
   *
   * @instance
   * @memberOf WorkingAtGradeLevelComponent
   */
  @computed('workingAtGradeLevelData')
  get maxChartValue() {
    const { below, at, above } = this.workingAtGradeLevelData
    return below + at + above
  }

  @computed('maxChartValue')
  get yAxis() {
    const max = this.maxChartValue

    return {
      startOnTick: false,
      endOnTick: false,
      gridLineWidth: 0,
      max,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      reversedStacks: false,
    }
  }

  /**
   * The colours object, as retrieved for this chart from utils/colour-picker
   *
   * @instance
   * @memberOf WorkingAtGradeLevelComponent
   */
  @computed('product')
  get colours() {
    const { product } = this
    const coloursObject = colourPicker(product, 'workingAtGradeLevel')
    return coloursObject
  }

  /**
   * Data that will be provided to the legend for labels
   *
   * @instance
   * @memberOf WorkingAtGradeLevelComponent
   */
  @computed('workingAtGradeLevelData', 'colours')
  get legendData() {
    const { colours } = this
    const studentNumbersByKey = this.workingAtGradeLevelData
    const categories = [
      { key: 'below', label: 'Below' },
      { key: 'at', label: 'On' },
      { key: 'above', label: 'Above' },
    ]
    const legendData = categories.map((category) => {
      const { label, key } = category
      const colour = colours[key]
      const data = Math.round(studentNumbersByKey[key])
      return { label, colour, data }
    })
    return legendData
  }

  chartMargin = [60, 10, 160, 10]
}
