import Controller from '@ember/controller'
import { computed } from '@ember/object'
import { equal, alias } from '@ember/object/computed'

export default class TimeOnTaskController extends Controller {
  @alias('model.scope.scope')
  scopeName

  @equal('scopeName', 'district')
  isDistrictScope

  @computed('scopeName', 'model.{scope.id,scope.scope,scopedData.districts.[],scopedData.schools.[]}')
  get activeScopeName() {
    const uiScope = this.model.scope
    if (uiScope.scope === 'district') {
      return this.model.scopedData.districts.find((district) => district.id === uiScope.id)?.name ?? ''
    }
    return this.model.scopedData.schools.find((school) => school.id === uiScope.id)?.name ?? ''
  }

  @computed('model.timeOnTaskUsage.[]')
  get timeOnTaskUsage() {
    const { timeOnTaskUsage } = this.model
    return {
      summary: timeOnTaskUsage.time_on_task_summary,
      months: timeOnTaskUsage.time_on_task_by_month,
    }
  }
}
