import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { isPresent } from '@ember/utils'
import { statefulSort } from 'district-ui-client/utils/giraffe/stateful-sort'
import type MathsDashboardTableRoute from 'district-ui-client/routes/reporting/ui-scope/maths/dashboard/table'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type SessionService from 'district-ui-client/services/session'

type EmailAllStudentsCsvService = any

export default class TableController extends Controller {
  @service emailAllStudentsCsv!: EmailAllStudentsCsvService

  @service session!: SessionService

  @tracked currentSortColumn = null

  declare model: ModelFor<MathsDashboardTableRoute>

  get isSummaryDataPresent() {
    return isPresent(this.model.summary) && isPresent(this.model.summary?.summary.schools)
  }

  get rows() {
    const schools = this.model.scopedSchools
    const summaryStats = this.model.summary?.summary?.schools ?? []
    return schools.map((school) => {
      const statsForSchool = summaryStats.find((stat) => String(stat.school_id) === school.id)
      const schoolWithStats = {
        school_name: school.name,
        contributing_students: school.utilisationFor(SubscriptionType.Maths)?.studentCount ?? 0,
        ...statsForSchool,
      }
      return schoolWithStats
    })
  }

  get summaryRow() {
    return {
      ...(this.model.summary?.summary.district || {}),
      contributing_students: this.model.scopedSchools.reduce(
        (total, school) => total + (school.utilisationFor(SubscriptionType.Maths)?.studentCount ?? 0),
        0,
      ),
    }
  }

  statefulSort = (...args: Parameters<typeof statefulSort>) => {
    return statefulSort.apply(this, args)
  }

  emailAllStudentsCSV = () => {
    const districtId = this.session.currentDistrict.id
    const product = this.model.product
    const period = this.model.period
    this.emailAllStudentsCsv.emailAllStudentsCSV(districtId, product, period)
  }
}
