import { isEmpty } from '@ember/utils'
import { Promise as EmberPromise } from 'rsvp'

/**
 * Fetches the stadium_events_categories endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function stadiumEventsCategories(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return EmberPromise.resolve(null)

  const url = this.buildUrl('stadium_events_categories', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    if (isEmpty(data) || isEmpty(data.stadium_events_categories)) {
      return null
    }

    return data
  })
}
