import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'
import flattenSummaryData from '../../utils/flatten-summary-data'

/**
 * Fetches the stories_written_by_month_events endpoint from the student-events API
 *
 * @param {String} product - The product this request is for
 * @param {Object} studentScope - The studentScope this request is for
 * @param {String} studentScope.scope -  The scope type, e.g. students, district
 * @param {Array} studentScope.ids - A list of scope type ids to query
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or null if data is empty
 */
export default function storiesWrittenStudent(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)
  const url = this.buildUrl(
    'stories_written_by_month_events',
    product,
    studentScope,
  )
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const {
      stories_written_by_month_events_summary: summary,
      stories_written_by_month_events: byMonth,
    } = data

    if (byMonth.length === 0) {
      return null
    }

    return {
      stories_written_summary: flattenSummaryData(summary.data),
      stories_written_by_month: fillMonths(byMonth, period, {
        count: null,
      }),
    }
  })
}
