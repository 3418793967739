import { isEmpty } from '@ember/utils'

/**
 * Fetches the mental_minute_summary endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default async function skillsMastered(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl('skills_mastered', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const data = await this.fetchData(url, query)
  const { skills_mastered } = data

  const newSkillsMastered = {}
  if (product === 're' && skills_mastered) {
    return { skills_mastered }
  }
  if (product === 'maths' && skills_mastered) {
    // if at least 1 catergory has a value above 0, return data, with any missing categories added. if all 0's, return null
    let dataExists = false
    // example payload
    // {skills_mastered: {number: {total: 17, average: 3}, fractions: {total: 22, average: 4}, operations: {total: 5, average: 1}, measurement: {total: 7, average: 1}, geometry: {total: 0, average: 0}}}
    const categories = [
      'data',
      'fractions',
      'geometry',
      'measurement',
      'number',
      'operations',
    ]
    const defaultCategoryData = { average: 0, total: 0 }
    const categoryDataKeys = Object.keys(defaultCategoryData)

    categories.forEach((category) => {
      const categoryData = skills_mastered[category] || {}
      dataExists =
        dataExists ||
        categoryDataKeys.some((dataKey) => categoryData[dataKey] > 0)
      newSkillsMastered[category] = { ...defaultCategoryData, ...categoryData }
    })
    return dataExists ? { skills_mastered: newSkillsMastered } : null
  }
  return null
}
