import { classNames } from '@ember-decorators/component'
import Component from '@ember/component'
import { get, computed } from '@ember/object'
import { getColour } from 'district-ui-client/utils/giraffe/colour-picker'
import BlakeColours from '@blakeelearning/blake-colours/colours'

/**
 * Display pie charts of driving test results
 *
 * @class DrivingTestsComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object[]} data.driving_tests_by_grade - The array of data by grade
 */
@classNames('giraffe-dashboard-tile', 'giraffe-dashboard-driving-tests', 'h-full')
export default class DrivingTests extends Component {
  /**
   * object passed in which allows for us to look up
   * the correctly translated short grade name for the
   * driving test grade
   */
  gradeToShortNameMap = null

  /**
   * The colours for the chart
   *
   * @instance
   * @memberOf DrivingTestsComponent
   */
  @computed
  get colours() {
    const notCompleted = getColour('maths.drivingTestsPercentage.notCompleted')
    const completed = getColour('maths.drivingTestsPercentage.completed')
    return [notCompleted, completed]
  }

  /**
   * Controls the classname of surrounding div
   *
   * @instance
   * @memberOf DrivingTestsComponent
   */
  @computed('data.driving_tests_by_grade.length')
  get columnsPerGraph() {
    const testsLength = get(this, 'data.driving_tests_by_grade.length')
    if (!testsLength) {
      return 4
    }
    return 12 / testsLength
  }

  /**
   * Controls the width of pie-chart-wrapper
   *
   * @instance
   * @memberOf DrivingTestsComponent
   */
  @computed('data.driving_tests_by_grade.length')
  get numberOfPiesClass() {
    const numberOfPies = get(this, 'data.driving_tests_by_grade.length')
    const pieClasses = ['', 'w-full', 'w-1/2', 'w-1/3']

    return pieClasses[numberOfPies]
  }

  /**
   * The input data for the chart, formatted for Highcharts.
   *
   * @instance
   * @memberOf DrivingTestsComponent
   */
  @computed('data.driving_tests_by_grade', 'gradeToShortNameMap', 'pieSize')
  get chartInputData() {
    const gradeToShortNameMap = this.gradeToShortNameMap || {}
    const mapped = this.data.driving_tests_by_grade.map((drivingTest) => {
      const grade = drivingTest.grade_level
      const shortGradeName = gradeToShortNameMap[grade] || '?'
      const completed = drivingTest.quiz_completed_percentage
      const notCompleted = 100 - completed
      const size = this.pieSize
      const inputData = {
        series: [
          {
            data: [
              ['completed', completed],
              ['notCompleted', notCompleted],
            ],
            dataLabels: {
              enabled: false,
            },
          },
        ],
        innerSize: '80%',
        size,
        subtitle: `<h1>${shortGradeName}</h1>`,
        label: completed,
      }
      return inputData
    })
    return mapped
  }

  /**
   * The size of the pie should be smaller if there is only data for 1 grade.
   *
   * @instance
   * @memberOf DrivingTestsComponent
   */
  @computed('data.driving_tests_by_grade.length')
  get pieSize() {
    const testsLength = get(this, 'data.driving_tests_by_grade.length')
    if (testsLength === 1) {
      return '80%'
    }
    return '100%'
  }

  @computed('tooltipFormatter')
  get tooltip() {
    return {
      enabled: true,
      backgroundColor: BlakeColours.dustyBlack400,
      borderWidth: 0,
      borderRadius: 5,
      formatter: this.tooltipFormatter,
      shadow: false,
      style: { color: BlakeColours.white },
    }
  }

  tooltipFormatter() {
    return `<b>${Math.round(this.y)}%</b>`
  }
}
