import Component from '@glimmer/component'
import { service } from '@ember/service'
import { camelize } from '@ember/string'
import { reportingLegend as legend } from 'district-ui-client/utils/giraffe/highcharts-config'
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { getColour } from 'district-ui-client/utils/giraffe/colour-picker'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import type { IntlService } from 'ember-intl'
import type Features from 'district-ui-client/services/features'

/**
 * Shows usage over time amongst key categories, and summary factoids above
 * the chart for each category.
 */

interface Box {
  category: string
  tooltip?: string
  theme?: string
}

interface Stat {
  name: string
  value: number | string
}

interface Month {
  month: string
  count: number
}

interface MonthUsage {
  count: number
  category: string
  months: Month[]
}

interface TimeSummary {
  category: string
  event_count: number
  student_count: number
}

interface ChartArgs {
  data: {
    total_usage_over_time_summary: TimeSummary[]
    total_usage_over_time_by_month: MonthUsage[]
  }

  boxes: Box[]
  product: string
  tileClickAction?: (category: string) => void
  uiScope: string
}

interface StatBox {
  title: string
  category: string
  stats: Stat[][]
  noClickAction?: boolean
}
interface ChartDataItem {
  name: string
  category: string
  data: number[]
  color: string
}

export default class GiraffeChartsTotalUsageOverTime extends Component<ChartArgs> {
  @service intl!: IntlService

  @service features!: Features

  legend: unknown = legend('left', 'top', 40, 10)

  tileClickAction = (statBox: StatBox, category: string) => {
    if (statBox.noClickAction) return
    this.args.tileClickAction?.(category)
  }

  get summaryData() {
    return this.args.data.total_usage_over_time_summary
  }

  get monthData() {
    return this.args.data.total_usage_over_time_by_month
  }

  // This preserves the order that the stat boxes should be displayed in
  get categories() {
    return this.args.boxes.map((item) => item.category)
  }

  /**
   * Converts the category array an object like { 'lessons': { tooltip: '..', abc: .. }, 'some-other-category':, { .. } }
   * For easier access to category properties
   */
  get keyedBoxes(): Record<string, Record<Box['category'], Box>> {
    return this.args.boxes.reduce((keyed, box) => {
      return { ...keyed, [box.category]: box }
    }, {})
  }

  // Returns an array of stat box objects, each with the data required for a stat box, in the intended display order.
  get statBoxes(): StatBox[] {
    const titlePrefix = 'reporting.totalUsageOverTime.titles'
    const statPrefix = 'reporting.statBoxes.totalUsageOverTime'

    return this.categories
      .map<StatBox | null>((category) => {
        const categorySummaryData = this.summaryData.find((summary) => summary.category === category)

        if (!categorySummaryData) return null

        const { event_count, student_count } = categorySummaryData
        const average = basicAverage(event_count, student_count)

        const camelizedCategory = camelize(category)
        const camelizedProduct = camelize(this.args.product)

        let title = this.intl.t(`${titlePrefix}.${camelizedCategory}.${camelizedProduct}`)
        if (this.intl.exists(`${titlePrefix}.${camelizedCategory}`)) {
          title = this.intl.t(`${titlePrefix}.${camelizedCategory}`)
        }

        const scopeSiteCopyKey = category === 'mental_minute' ? 'sprints' : camelize(this.args.uiScope)

        const firstStatLabel = this.intl.t(`${statPrefix}.${scopeSiteCopyKey}`)
        const secondStatLabel = this.intl.t(`${statPrefix}.perStudent`)

        return {
          title,
          category,
          stats: [
            [
              { name: firstStatLabel, value: formatNumber(event_count, 1) as number | string },
              { name: secondStatLabel, value: formatNumber(average, 1) as number | string },
            ],
          ],
          ...this.keyedBoxes[category],
        }
      })
      .filter((sb): sb is StatBox => sb !== null)
  }

  // Not ideal. But we can't join class strings like `xl:w-1/{{statBoxes.length}}` or the class will not be included.
  // We probably want to come up with a better solution for XL screen sizes though
  get statBoxesXlWidthClass() {
    switch (this.statBoxes.length) {
      case 4:
        return 'xl:w-1/4'
      case 5:
        return 'xl:w-1/5'
      case 6:
        return 'xl:w-1/6'
      default:
        return ''
    }
  }

  // These will be the chart X axis categories, use the months given in the first data item.
  get chartMonths() {
    const monthData = this.monthData[0].months
    return monthData.map(({ month }) => convertDateFormat(month, 'yyyy-MM', 'MMMM'))
  }

  // Format and sort data for the series column chart
  get chartData(): ChartDataItem[] {
    const { categories, monthData } = this

    const legendPrefix = 'reporting.totalUsageOverTime.legends'

    return categories
      .map<ChartDataItem | null>((category) => {
        if (category === 'quizzes' && !this.features.isEnabled('tuiReadingSkillsMasteredFF')) return null

        const dataItem = monthData.find((item) => item.category === category)
        if (!dataItem) return null

        const byMonth = dataItem.months.map((item) => item.count)

        const camelizedCategory = camelize(category)
        const camelizedProduct = camelize(this.args.product)

        let name = this.intl.t(`${legendPrefix}.${camelizedCategory}.${camelizedProduct}`)
        if (this.intl.exists(`${legendPrefix}.${camelizedCategory}`)) {
          name = this.intl.t(`${legendPrefix}.${camelizedCategory}`)
        }

        const colorKey = `${camelizedProduct}.totalUsageOverTime.${camelizedCategory}`
        const color = getColour(colorKey)

        return { name, category, data: byMonth, color }
      })
      .filter((cd): cd is ChartDataItem => cd !== null)
  }
}
