import Route from '@ember/routing/route'
import { action } from '@ember/object'
import { service } from '@ember/service'
import { assert } from '@ember/debug'
import { isAbortError, isUnauthorizedError, isForbiddenError } from 'district-ui-client/errors/http-error'
import config from 'district-ui-client/config/environment'
import type RouterService from '@ember/routing/router-service'
import type SessionService from 'district-ui-client/services/session'
import type { IntlService } from 'ember-intl'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import type MetricsService from 'ember-metrics/services/metrics'
import type { Log } from '@blakeelearning/log'
import type Store from '@ember-data/store'
import type DateFilterService from '@blakeelearning/dates/services/date-filter'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type ReleaseCheckerService from '@blakeelearning/app-refresher/release-checker/service'
import type LongSessionKillerService from '@blakeelearning/app-refresher/long-session-killer/service'
import type SessionTrackerService from '@blakeelearning/app-refresher/session-tracker/service'
import type RefresherService from '@blakeelearning/app-refresher/refresher/service'
import type Transition from '@ember/routing/transition'
import type DistrictCoordinator from 'district-ui-client/models/district-coordinator'
import type { DecodedAuthToken } from 'district-ui-client/services/session'

export default class ApplicationRoute extends Route {
  @service
  router!: RouterService

  @service
  metrics!: MetricsService

  @service
  dateFilter!: DateFilterService

  @service
  session!: SessionService

  @service
  intl!: IntlService

  @service
  releaseChecker!: ReleaseCheckerService

  @service
  longSessionKiller!: LongSessionKillerService

  @service
  sessionTracker!: SessionTrackerService

  @service
  authToken!: AuthToken

  @service
  log!: Log

  @service
  refresher!: RefresherService

  @service
  gradeSets!: GradeSetsService

  @service
  store!: Store

  @service
  studentEvents!: StudentEventsService

  // This service has constructor that hooks into routeDidChange router events, which we want early in the app
  // eslint-disable-next-line ember/no-restricted-service-injections
  @service activeRoute!: ActiveRouteService

  refreshTokenTimer?: number

  constructor(...args: never[]) {
    super(...args)
    this.router.on('routeDidChange', this.handleRouteChange)
  }

  willDestroy() {
    super.willDestroy()
    this.router.off('routeDidChange', this.handleRouteChange)
  }

  private handleRouteChange = () => {
    this.trackPage()
  }

  private trackPage() {
    const page = this.router.currentURL
    const title = this.router.currentRouteName || 'unknown'

    this.metrics.trackPage({ page, title })
  }

  async beforeModel(transition: Transition) {
    /* Set a temporary default language here, so that if an error occurs we can still show error messaging from
     * translations. For example, the application error substate page uses intl translation keys.
     * The language is set again in the afterModel once we have the disco model and know the selected language to use.
     */
    this.intl.setLocale(['en'])
    return this.authToken.getTokenForDisco().match(
      async (_token) => {
        this.releaseChecker.start()
        this.longSessionKiller.start()
        const { decodedToken } = this.authToken
        this.session.setAuthUser(decodedToken as unknown as DecodedAuthToken)
        this.studentEvents.baseUrl = config.studentEventsV3Url
        this.studentEvents.setupRequestInit = () => {
          return this.authToken.token ? { headers: { Authorization: this.authToken.token } } : {}
        }
        return Promise.resolve()
      },
      (_error) => {
        // Abort the transition to ensure no further model hooks take place, and redirect to login
        void transition.abort()
        this.authToken.redirectToLogin()
      },
    )
  }

  async model() {
    const { authUser } = this.session
    assert('authUser is not set', authUser)

    const [districtCoordinator, district] = await Promise.all([
      // reload ensures promises wait until request finishes (in case record already in cache without includes)
      this.store.findRecord('district-coordinator', authUser.id, { reload: true }),
      this.store.findRecord('district', authUser.districtId, {
        include: 'clever-district-match,standards-set',
        reload: true,
      }),
    ])
    this.session.setDistrictCoordinator(districtCoordinator)
    this.session.setDistrict(district)

    return { districtCoordinator, district }
  }

  async afterModel({ districtCoordinator }: { districtCoordinator: DistrictCoordinator }) {
    this.dateFilter.setCountryCode(districtCoordinator.countryCode)

    const language = districtCoordinator.language || 'en-us'
    const locale = String(language).toLowerCase()
    this.intl.setLocale([locale, 'en'])

    this.sessionTracker.start(districtCoordinator.id)

    await this.gradeSets.loadGradeSets()

    this.scheduleRefresh()
  }

  scheduleRefresh() {
    if (!config.autoRefreshJWT.enabled) return

    const expiry = this.authToken.tokenExpiry

    const oneMinute = 60 * 1000

    const refreshAt = expiry - oneMinute - Date.now() // Refresh the JWT a minute before it expires

    this.refreshTokenTimer = setTimeout(() => {
      void this.refreshToken()
    }, refreshAt)
  }

  async refreshToken() {
    await this.authToken.refreshToken()
    this.scheduleRefresh()
  }

  deactivate() {
    if (this.refreshTokenTimer) clearTimeout(this.refreshTokenTimer)
  }

  willTransition = (transition: Transition) => {
    void transition.followRedirects().then(() => this.refresher.refreshIfScheduled())
  }

  // Route error handlers are called synchronously so cannot return a promsie
  // which also means we can't use async/await
  @action
  error(error: unknown, _transition: Transition) {
    // don't report abort errors
    if (isAbortError(error)) {
      return false
    }

    // if unauthorized or forbidden, their token would have expired - send them to login and dont report.
    if (isUnauthorizedError(error) || isForbiddenError(error)) {
      this.authToken.redirectToLogin()
      return false
    }

    if (error instanceof Response) {
      void error.text().then((text) => this.log.error('unknown error occurred', text))
    } else if (error) {
      this.log.error('unknown error occurred', error)
    } else {
      this.log.error('unknown error occurred')
    }

    return true
  }
}
