import { A } from '@ember/array'
import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'

/**
 * Fetches the total_usage_over_time endpoint from the student-events API,
 * filling in any empty months
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function totalUsageOverTime(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('total_usage_over_time', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const summaryData = data.total_usage_over_time_summary
    const events = A(summaryData).mapBy('event_count')
    const sumOfEvents = events.reduce((acc, sum) => acc + sum, 0)
    if (sumOfEvents === 0) {
      return null
    }

    let monthData = data.total_usage_over_time_by_month
    if (!isEmpty(monthData)) {
      monthData = monthData.map((category) => ({
        ...category,
        months: fillMonths(category.months, period, { count: null }),
      }))
    } else {
      monthData = null
    }

    return {
      ...data,
      total_usage_over_time_by_month: monthData,
    }
  })
}
