import { isEmpty } from '@ember/utils'

/**
 * Fetches the summary endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 * The shape of this data depends on whether the scope is district, as do the empty checks
 */
export default async function studentSummary(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl('student_summary', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const data = await this.fetchData(url, query)

  const noData = isEmpty(data?.fast_phonics_student_summary?.students)
  return noData ? null : data?.fast_phonics_student_summary
}
