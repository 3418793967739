import { tagName } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'
import Component from '@ember/component'

/**
 * An ember component interface to a highcharts pie chart.
 *
 * Data is in this format:
 *
 * ```
 * series: [{
 *   data: [['completed', completed], ['incompleted', incompleted]],
 *   dataLabels: {
 *     enabled: false,
 *   },
 * }],
 *   innerSize: '80%',
 *   size,
 *   subtitle: `<h1>${formatShortName(grade)}</h1>`,
 *   label: completed,
 * })
 * ```
 *
 *
 * @class PrimitivePieChart
 *
 * @property {Array} colours - The colours for the different series
 * @property {Boolean} [colorByPoint=false] - if true, colours apply per 'point' (column), rather than per series
 * @property {Number} [columnPadding=0.1] - Number between 0 and 1 controlling how much padding a
 *                                          column has in the space available to it.
 * @property {String} [subtitle=''] - Chart subtitle text
 * @property {String} [verticalAlign='middle'] - Vertical position of subtitle within chart
 * @property {HighChartsSeries} data - The series data
 * @property {HighChartsLegend} [legend=disabled] - Highcharts legend config
 * @property {String|HighChartsTitle} [title=null] - Chart title is null. Current charts use dashboard wrapper title.
 * @property {String|HighChartsSubtitle} - Chart subtitle which is passed into the primitive.
 * @example
 *  {{primitives/pie-chart
 *    subtitle=chartData.subtitle
 *    product=product
 *    colours=colours
 *    size=chartData.size
 *    innerSize=chartData.innerSize
 *  }}
 *
 */
@tagName('')
export default class PieChart extends Component {
  colours = undefined

  @alias('colours')
  colors

  @computed('chartMargin', 'chartSpacing')
  get chartOptions() {
    const disabled = { enabled: false }

    const { colors, highchartSubtitle: subtitle, plotOptions, tooltip } = this
    const backgroundColor = 'transparent'
    const margin = this.chartMargin === undefined ? 0 : this.chartMargin
    const spacing = this.chartSpacing === undefined ? 10 : this.chartSpacing

    const options = {
      colors,
      chart: {
        type: 'pie',
        backgroundColor,
        margin,
        spacing,
      },
      credits: disabled,
      plotOptions,
      tooltip,
      title: null,
      subtitle,
      legend: disabled,
    }
    return options
  }

  @computed('subtitle', 'verticalAlign')
  get highchartSubtitle() {
    const text = this.subtitle === undefined ? '' : this.subtitle
    const verticalAlign = this.verticalAlign === undefined ? 'middle' : this.verticalAlign
    return {
      text,
      verticalAlign,
    }
  }

  @computed('innerSize', 'size')
  get plotOptions() {
    const innerSize = this.innerSize === undefined ? '80%' : this.innerSize
    const size = this.size === undefined ? '100%' : this.size
    return {
      pie: {
        innerSize,
        size,
      },
    }
  }

  get highchartOptions() {
    return { ...this.chartOptions, series: this.data }
  }
}
