import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'

/**
 * Fetches the time_on_task endpoint
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function timeOnTaskUsage(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('time_on_task_usage', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { time_on_task_summary: summary, time_on_task_by_month: months } =
      data

    if (isEmpty(months)) {
      return null
    }

    return {
      time_on_task_summary: summary,
      time_on_task_by_month: fillMonths(months, period, { seconds: null }),
    }
  })
}
