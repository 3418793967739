import { service } from '@ember/service'
import RSVP from 'rsvp'
import Route from '@ember/routing/route'

export default class AveragesRoute extends Route {
  @service
  studentEvents

  async model() {
    const product = 're'
    const { uiScope: scope, statsScope, period, scopedData } = this.modelFor('reporting.ui-scope')

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product, scope, period) {
    const { studentEvents } = this
    const precinct = 'reading'

    return RSVP.hash({
      courseProgressAverages: studentEvents.courseProgressAverages(product, precinct, scope, period),
      precinct,
    })
  }
}
