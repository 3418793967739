import { tagName } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { service } from '@ember/service'
import { readOnly } from '@ember/object/computed'
import Component from '@ember/component'
import { formatEmpty } from '@blakeelearning/stable-table/utils/st/format-empty'
import { themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box'

@tagName('')
export default class MentalMinute extends Component {
  @readOnly('data.summary') summaryData

  @service intl

  translatePrefix = 'reporting.charts.dashboard.mentalMinute'

  categories = ['addition', 'subtraction', 'multiplication', 'division']

  themes = {
    addition: statBoxThemes.THEME_OCEANY_BLUE,
    subtraction: statBoxThemes.THEME_JUICY_ORANGE,
    multiplication: statBoxThemes.THEME_MS_GREEN,
    division: statBoxThemes.THEME_PURPLY_PINK,
  }

  @computed('summaryData')
  get categorySummaries() {
    const { categories, themes, intl, translatePrefix } = this
    const summaryData = this.summaryData || {}

    return categories.map((category) => {
      const categoryData = summaryData[category] || {}
      const title = intl.t(`${translatePrefix}.categories.${category}`)

      const sprintsStatName = intl.t(`${translatePrefix}.stats.sprintsMastered`)
      const sprintsStatValue = formatEmpty(categoryData.sprints_mastered)
      const sprintsStat = { name: sprintsStatName, value: sprintsStatValue }

      const improvementStatName = intl.t(`${translatePrefix}.stats.improvement`)
      const improvementStatValue = formatEmpty(categoryData.improvement, { append: '%' })
      const improvementStat = { name: improvementStatName, value: improvementStatValue }

      const stats = [[sprintsStat], [improvementStat]]

      const theme = themes[category]

      return { title, theme, category, stats }
    })
  }
}
