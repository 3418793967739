import { isEmpty } from '@ember/utils'

/**
 * Fetches the driving_tests_events endpoint from the student-events API
 *
 * @param {String} product - The product this request is for
 * @param {Object} studentScope - The studentScope this request is for
 * @param {String} studentScope.scope -  The scope type, e.g. students, district
 * @param {Array} studentScope.ids - A list of scope type ids to query
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or null if data is empty
 */
export default async function drivingTestsStudent(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl('driving_tests_events', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const data = await this.fetchData(url, query)
  if (isEmpty(data?.driving_tests_events_summary)) return null

  // Maths: [0, 1, 2], Reading: [null]
  const grades = data.driving_tests_events_summary.map(
    (summary) => summary.driving_test_grade,
  )
  return grades.map((dtGrade) => {
    const hasMatchingDtGrade = (dataItem) =>
      dataItem.driving_test_grade === dtGrade
    return {
      driving_test_grade: dtGrade,
      summary:
        data.driving_tests_events_summary.find(hasMatchingDtGrade).data ?? [],
      categories:
        data.driving_tests_events.find(hasMatchingDtGrade).events ?? [],
    }
  })
}
