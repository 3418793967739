import { classNames } from '@ember-decorators/component'
import Component from '@ember/component'
import { get, computed } from '@ember/object'
import { capitalize } from '@ember/string'
import { getColour } from 'district-ui-client/utils/giraffe/colour-picker'
import { reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { roundAccurately } from 'district-ui-client/utils/round'

/**
 * Display a donut chart of books read by genre (fiction/nonfiction).
 *
 * @class BooksReadByGenreComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.books_read_by_genre - The data object namespace
 * @property {Number} data.books_read_by_genre.fiction - The number of fiction books read
 * @property {Number} data.books_read_by_genre.nonfiction - The number of nonfiction books read
 */
@classNames('giraffe-dashboard-tile', 'giraffe-dashboard-books-read-by-genre', 'relative', 'h-full', 'pb-7')
export default class ByGenre extends Component {
  /**
   * The title of the chart
   *
   * @instance
   * @memberOf BooksReadByGenreComponent
   */
  title = 'Books read'

  /**
   * The input data for the chart, formatted for Highcharts.
   *
   * @instance
   * @memberOf BooksReadByGenreComponent
   */
  @computed('booksReadData.[]', 'totalCount')
  get chartData() {
    const subtitle = formatNumber(this.totalCount, 1)
    const data = this.booksReadData
    const chartData = {
      series: [
        {
          data,
          dataLabels: { enabled: false },
          showInLegend: true,
        },
      ],
      innerSize: '80%',
      size: '80%',
      subtitle: `<h1>${subtitle}<h1>`,
    }
    return chartData
  }

  /**
   * The input data for the chart, formatted for Highcharts.
   *
   * @instance
   * @memberOf BooksReadByGenreComponent
   */
  @computed('data.books_read_by_genre.{fiction,nonfiction}')
  get booksReadData() {
    const values = get(this, 'data.books_read_by_genre')
    const keys = ['nonfiction', 'fiction']
    return keys.map((key) => {
      return [capitalize(key), values[key]]
    })
  }

  /**
   * Sum of the data in both genres to display as the total number of books read.
   *
   * @instance
   * @memberOf BooksReadByGenreComponent
   */
  @computed('data.books_read_by_genre.{fiction,nonfiction}')
  get totalCount() {
    const values = get(this, 'data.books_read_by_genre')
    const keys = Object.keys(values)
    return keys.reduce((total, key) => {
      return total + values[key]
    }, 0)
  }

  /**
   * The colours for the chart
   *
   * @instance
   * @memberOf BooksReadByGenreComponent
   */
  @computed('product')
  get colours() {
    const { product } = this
    const colours = getColour(`${product}.booksReadByGenrePie`)
    return [colours.nonfiction, colours.fiction]
  }

  /**
   * Data for the chart legend. Displays each genre as a percentage of the total books read.
   *
   * @instance
   * @memberOf BooksReadByGenreComponent
   */
  @computed('totalCount', 'booksReadData.[]')
  get legendData() {
    const total = this.totalCount
    const data = this.booksReadData

    const [[nonfictionName, nonfiction], [fictionName, fiction]] = data

    let fictionPercentage = 0
    let nonfictionPercentage = 0

    if (total !== 0) {
      fictionPercentage = (fiction / total) * 100
      nonfictionPercentage = (nonfiction / total) * 100
    }

    return {
      left: { name: fictionName, percentage: roundAccurately(fictionPercentage, 1) },
      right: { name: nonfictionName, percentage: roundAccurately(nonfictionPercentage, 1) },
    }
  }

  tooltip = toolTipConfig('y', null, {
    formatter() {
      const formattedValue = formatNumber(this.y, 2)
      return `<b>${this.point.name}</b>: ${formattedValue}`
    },
  })
}
