import { A as emberArray } from '@ember/array'
import { assert } from '@ember/debug'
import emberObject, { get } from '@ember/object'
import { isEmpty } from '@ember/utils'
import range from 'lodash/range'

const rex = emberObject.create({
  2: { min: 180, max: 339 },
  3: { min: 340, max: 599 },
  4: { min: 600, max: 731 },
  5: { min: 732, max: 841 },
  6: { min: 842, max: 924 },
  7: { min: 925, max: 1100 },
})

const re = emberObject.create({
  1: { min: 0, max: 114 },
  2: { min: 115, max: 263 },
  3: { min: 264, max: 320 },
})

const data = emberObject.create({ rex, re })

function isProduct(product) {
  return Object.keys(data).indexOf(product) !== -1
}

/**
 * Lookup the grade position from a lexile value for a product.
 *
 * @memberOf LexileGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {Number} lexile - The lexile value to lookup the matching grade position
 * @return {Number} The grade position that matches the lexile value for the product.
 */
export function gradePositionFromLexile(product, lexile) {
  assert('Please pass a valid product name', isProduct(product))

  const value = parseInt(lexile, 10)
  const { [product.toLowerCase()]: productData } = data
  const [gradePosition] = emberArray(
    Object.keys(productData).map((grade) => {
      const { min, max } = get(productData, grade)

      if (value >= min && value <= max) {
        return parseInt(grade, 10)
      }
      return null
    }),
  ).reject(isEmpty)

  return gradePosition
}

export function gradePositionRangeFromLexiles(product, lexiles, positionPadding = 0) {
  assert('Please pass a valid product name', isProduct(product))

  const gradePositions = emberArray(
    lexiles.map((lexile) => {
      return gradePositionFromLexile(product, lexile)
    }),
  ).compact()

  if (isEmpty(gradePositions)) return []

  const maxGradePosition = Math.max(...gradePositions) + positionPadding
  const minGradePosition = Math.min(...gradePositions)

  return range(minGradePosition, maxGradePosition + 1)
}

/**
 * Lookup the lexile range from a grade position for a product.
 *
 * @memberOf LexileGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {Number} grade - The grade to lookup the matching lexile range
 * @return {Object} The lexile range that matches the grade position for the product
 */
export function lexileRangeFromGradePosition(product, grade) {
  assert('Please pass a valid product name', isProduct(product))

  const value = parseInt(grade, 10)
  const { [product.toLowerCase()]: productData } = data

  return productData[value] || {}
}
