import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatEmpty } from '@blakeelearning/stable-table/utils/st/format-empty'

export default class SkillsMastered extends Component {
  @service intl

  get skillsMasteredData() {
    return this.args.data?.skills_mastered
  }

  translatePrefix = 'reporting.charts.dashboard.skillsMastered'

  categories = ['number', 'fractions', 'operations', 'measurement', 'geometry', 'data']

  categoryThemes = {
    number: 'text-mid-orange-300',
    fractions: 'text-oceany-blue-300',
    operations: 'text-rexy-purple-200',
    measurement: 'text-purply-pink-300',
    geometry: 'text-storm-blue-300',
    data: 'text-juicy-orange-300',
  }

  get skillsMasteredCategoryData() {
    const { categories, intl, translatePrefix, categoryThemes } = this
    const skillsMasteredData = this.skillsMasteredData || {}

    return categories.map((category) => {
      const title = intl.t(`${translatePrefix}.categories.${category}`)

      const categoryData = skillsMasteredData[category] || {}

      const value = formatEmpty(categoryData.average, { replaceZero: true })

      const theme = categoryThemes[category]

      return { title, category, value, theme }
    })
  }
}
