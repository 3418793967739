import Component from '@glimmer/component'
import colors from '@blakeelearning/blake-colours/colours'
import { dependentKeyCompat } from '@ember/object/compat'

interface Args {
  data?: {
    assessment_scores?: {
      average_attempts?: number
      average_score?: number
      scores?: {
        /**
         * "0-49", "50-79" or "80-100"
         */
        range: string
        student_count: number
      }[]
    }
  }
}

/**
 * Display a chart of assessment scores
 */
export default class extends Component<Args> {
  get scores() {
    const desiredOrder = ['80-100', '50-79', '0-49']
    const scores = this.args.data?.assessment_scores?.scores ?? []
    return scores.sort((a, b) => desiredOrder.indexOf(a.range) - desiredOrder.indexOf(b.range))
  }

  /**
   * The input data for the chart
   */
  @dependentKeyCompat
  get bars() {
    return [{ data: this.scores.map((s) => s.student_count) }]
  }

  /**
   * The series labels for the chart.
   * A list of score ranges.
   */
  @dependentKeyCompat
  get categories() {
    return this.scores.map((s) => `${s.range}%`)
  }

  get colours() {
    return [colors.msGreen300, colors.yolkyYellow300, colors.watermelonyRed300]
  }
}
