import { template } from "@ember/template-compiler";
import { reportingTitle, reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config';
import BlakeColours from '@blakeelearning/blake-colours/colours';
import Component from '@glimmer/component';
import BaseHighcharterComponent from 'district-ui-client/components/base/highcharter';
import type { LegendOptions } from 'highcharts';
import type { TooltipOptions } from 'highcharts';
import type { XAxisOptions, YAxisOptions, XAxisPlotBandsOptions, YAxisPlotLinesOptions, XAxisPlotLinesOptions, YAxisPlotBandsOptions, Options, DataLabelsFormatterCallbackFunction, ColorString, PlotOptions, OptionsStackingValue, AxisLabelsFormatterCallbackFunction } from 'highcharts';
import type { SeriesColumnOptions } from 'highcharts';
/**
 * An ember component interface to a highcharts column chart.
 *
 * Data is in this format:
 *
 * ```
 * [
 *   { name: 'series-0-name', data: [1, 2, 3] },
 *   { name: 'series-1-name', data: [3, 4, 5] },
 * ]
 * ```
 *
 * Use 1 series for a column chart, multiple series for a stacked or grouped
 * column chart.
 *
 *
 * @example
 *   {{primitives/series-column
 *     data=multiSeriesData
 *     colours=multiSeriesColours
 *     categories=categories
 *     legend=legend
 *   }}
 *
 */ interface Args {
    data: SeriesColumnOptions[];
    title?: string;
    chartSpacing?: number[];
    chartMargin?: (number | null)[];
    legend?: LegendOptions;
    toolTip?: TooltipOptions;
    categories?: string[];
    xAxisLabel?: string;
    xAxisVisible?: boolean;
    xAxisTickWidth?: number;
    xAxisPlotBands?: XAxisPlotBandsOptions[];
    xAxisPlotLines?: XAxisPlotLinesOptions[];
    xAxisLabelFormatter?: AxisLabelsFormatterCallbackFunction;
    yAxisLabel?: string;
    yAxisVisible?: boolean;
    yRange?: [number, number];
    yAxisPlotLines?: YAxisPlotLinesOptions[];
    yAxisPlotBands?: YAxisPlotBandsOptions[];
    yAxisReversedStacks?: boolean;
    yAxisEndOnTick?: boolean;
    yAxisTickInterval?: number;
    yAxisGridLineColor?: string;
    yAxisLabelFormatter?: AxisLabelsFormatterCallbackFunction;
    plotOptions?: PlotOptions;
    groupPadding?: number;
    columnPadding?: number;
    colorByPoint?: boolean;
    dataLabelsEnabled?: boolean;
    dataLabelsFormatter?: DataLabelsFormatterCallbackFunction;
    colours?: ColorString[];
    toolTipFormatter?: () => string;
    toolTipUnit?: string;
    stackingStyle?: OptionsStackingValue;
    animation?: boolean;
}
interface Signature {
    Args: Args;
    Element: HTMLDivElement;
}
const disabled = {
    enabled: false
};
export class SeriesColumn extends Component<Signature> {
    get colors() {
        return this.args.colours ?? undefined;
    }
    readonly plotBackgroundColor = BlakeColours.dustyBlack50;
    readonly yAxisGridLineColor = BlakeColours.dustyBlack100;
    get chartMargins() {
        if (!this.args.chartMargin) return {
            margin: undefined
        };
        const [marginTop1, marginRight1, marginBottom1, marginLeft1] = this.args.chartMargin;
        return {
            marginTop: marginTop1 ?? undefined,
            marginRight: marginRight1 ?? undefined,
            marginBottom: marginBottom1 ?? undefined,
            marginLeft: marginLeft1 ?? undefined
        };
    }
    get chartSpacing() {
        return this.args.chartSpacing ?? [
            10,
            10,
            10,
            10
        ];
    }
    get chartOptions(): Options {
        const tooltip1 = this.toolTip ?? disabled;
        const options1: Options = {
            chart: {
                type: 'column',
                plotBackgroundColor: this.plotBackgroundColor,
                backgroundColor: 'transparent',
                spacing: this.chartSpacing,
                ...this.chartMargins
            },
            credits: disabled,
            title: this.chartTitle,
            yAxis: this.yAxis,
            xAxis: this.xAxis,
            plotOptions: this.plotOptions,
            tooltip: tooltip1,
            legend: this.args.legend ?? disabled
        };
        if (this.colors) options1.colors = this.colors;
        return options1;
    }
    /**
   * the private computed xAxis property
   */ get xAxis(): XAxisOptions {
        const xAxisLabel1 = this.args.xAxisLabel ?? '';
        const visible1 = this.args.xAxisVisible ?? true;
        const tickWidth1 = this.args.xAxisTickWidth ?? 1;
        const plotBands1 = this.args.xAxisPlotBands ?? [];
        const plotLines1 = this.args.xAxisPlotLines ?? [];
        const { xAxisLabelFormatter: xAxisLabelFormatter1 } = this.args;
        const xAxis1: XAxisOptions = {
            title: reportingTitle(xAxisLabel1),
            categories: this.args.categories,
            visible: visible1,
            allowDecimals: false,
            tickWidth: tickWidth1,
            plotLines: plotLines1,
            plotBands: plotBands1,
            lineColor: BlakeColours.dustyBlack200,
            tickColor: BlakeColours.dustyBlack200
        };
        if (xAxisLabelFormatter1) {
            xAxis1.labels = {
                formatter: xAxisLabelFormatter1
            };
        }
        return xAxis1;
    }
    /**
   * the private computed yAxis property
   */ get yAxis(): YAxisOptions {
        const yAxisLabel1 = this.args.yAxisLabel ?? '';
        const visible1 = this.args.yAxisVisible ?? true;
        const [min1, max1] = this.args.yRange ?? [];
        const plotLines1 = this.args.yAxisPlotLines ?? [];
        const plotBands1 = this.args.yAxisPlotBands ?? [];
        const reversedStacks1 = this.args.yAxisReversedStacks ?? true;
        const endOnTick1 = this.args.yAxisEndOnTick ?? true;
        const { yAxisLabelFormatter: yAxisLabelFormatter1 } = this.args;
        const yAxis1: YAxisOptions = {
            title: reportingTitle(yAxisLabel1),
            visible: visible1,
            min: min1,
            max: max1,
            plotLines: plotLines1,
            plotBands: plotBands1,
            reversedStacks: reversedStacks1,
            allowDecimals: false,
            gridLineColor: this.args.yAxisGridLineColor,
            endOnTick: endOnTick1,
            tickInterval: this.args.yAxisTickInterval
        };
        if (yAxisLabelFormatter1) {
            yAxis1.labels = {
                formatter: yAxisLabelFormatter1
            };
        }
        return yAxis1;
    }
    get dataLabelsEnabled() {
        return this.args.dataLabelsEnabled ?? false;
    }
    get dataLabels(): Highcharts.DataLabelsOptions {
        // Only set formatter if it's defined, else it will override highcharts' default and error for charts that enable it
        if (this.args.dataLabelsFormatter) {
            return {
                enabled: this.dataLabelsEnabled,
                formatter: this.args.dataLabelsFormatter
            };
        }
        return {
            enabled: this.dataLabelsEnabled
        };
    }
    /**
   * the private computed plotOptions property
   */ get plotOptions(): PlotOptions {
        if (this.args.plotOptions) {
            return this.args.plotOptions;
        }
        const groupPadding1 = this.args.groupPadding ?? 0.2;
        const pointPadding1 = this.args.columnPadding ?? 0.1;
        const colorByPoint1 = this.args.colorByPoint ?? false;
        const options1 = {
            series: {
                dataLabels: this.dataLabels
            },
            column: {
                borderWidth: 0,
                stacking: this.args.stackingStyle,
                animation: this.args.animation,
                groupPadding: groupPadding1,
                pointPadding: pointPadding1,
                colorByPoint: colorByPoint1
            }
        };
        return options1;
    }
    get chartTitle() {
        const title1 = this.args.title === undefined ? '' : this.args.title;
        if (typeof title1 === 'string') {
            return reportingTitle(title1);
        }
        return title1;
    }
    get toolTip() {
        if (this.args.toolTip) {
            return this.args.toolTip;
        }
        const unit1 = this.args.toolTipUnit === undefined ? '' : this.args.toolTipUnit;
        return toolTipConfig('y', unit1, {
            formatter: this.args.toolTipFormatter
        });
    }
    get highchartOptions(): Options {
        return {
            ...this.chartOptions,
            series: this.args.data
        };
    }
    static{
        template(`<BaseHighcharterComponent class="h-full" @options={{this.highchartOptions}} ...attributes />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SeriesColumn;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Primitives::SeriesColumn': typeof SeriesColumn;
    }
}
