import { isEmpty } from '@ember/utils'

/**
 * Fetches the mental_minute_school_overview endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default async function mentalMinuteSchoolOverview(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl(
    'mental_minute_school_overview',
    product,
    studentScope,
  )
  const query = this.buildQuery(studentScope, period)

  const response = await this.fetchData(url, query)

  const isValid = validateResponse(response)

  if (!isValid) return null

  const data = response.mental_minute_school_overview
  // empty data is still valid, but return null when it happens
  return data.schools.length === 0 ? null : data
}

function validateResponse(response) {
  const hasAggregate =
    Object.keys(response?.mental_minute_school_overview?.aggregate ?? {})
      .length > 0
  const hasSchoolsArray = Array.isArray(
    response?.mental_minute_school_overview?.schools,
  )
  return hasAggregate && hasSchoolsArray
}
