import urlJoin from 'url-join'
import * as querystring from './querystring.ts'

export type URLLike = URI | URL

/**
 * Our custom URL object, implementing queryParams instead of searchParams.
 *
 * The [browser native URL][url] object treats the query portion of the url as an
 * ordered list of key-value pairs. This is incompatible with the ruby/rack
 * style of query parameters where it represents an object of arbitrary depth,
 * including potentially multi-dimensional arrays. It's the difference between:
 *
 * [url]: https://developer.mozilla.org/en-US/docs/Web/API/URL
 *
 * ```
 * JS way
 * ?foo=bar&foo=baz
 *
 * Ruby/Rack way
 * ?foo[]=bar&foo[]=baz
 * ```
 *
 * If you use ruby/rack format and try to get the value of foo from a browser
 * URL searchParams field, it won't work. It'll be stored as `foo[]`.
 *
 * Our querystring module can serialise and deserialise this format. This custom
 * URL type adds the queryParams key which is a single object, and hides the
 * searchParams key.
 *
 * The rest of the properties behave exactly like the DOM url.
 */

export class URI {
  private _url: URL

  private _queryParams: unknown

  constructor(url: string, base?: string | URL) {
    // safari will throw a TypeError when base is undefined
    if (base) {
      this._url = new URL(url, base)
    } else {
      this._url = new URL(url)
    }

    this._queryParams = querystring.deserialise(this._url.search)
  }

  __uri_url_duck_typing__ = true

  get hash(): string {
    return this._url.hash
  }

  set hash(value) {
    this._url.hash = value
  }

  get host(): string {
    return this._url.host
  }

  set host(value) {
    this._url.host = value
  }

  get hostname(): string {
    return this._url.hostname
  }

  set hostname(value) {
    this._url.hostname = value
  }

  get password(): string {
    return this._url.password
  }

  set password(value) {
    this._url.password = value
  }

  get origin(): string {
    return this._url.origin
  }

  get protocol(): string {
    return this._url.protocol
  }

  set protocol(value) {
    this._url.protocol = value
  }

  get username(): string {
    return this._url.username
  }

  set username(value) {
    this._url.username = value
  }

  get port(): string {
    return this._url.port
  }

  set port(value) {
    this._url.port = value
  }

  get pathname(): string {
    return this._url.pathname
  }

  set pathname(value) {
    this._url.pathname = value
  }

  get href(): string {
    this._url.search = this.search
    return fixPolyfillEmptyQuery(this._url.href)
  }

  set href(value) {
    this._url.href = value
    this._queryParams = querystring.deserialise(this._url.search)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get queryParams(): any {
    return this._queryParams
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set queryParams(value: any) {
    this._url.search = querystring.serialise(value)
    this._queryParams = value
  }

  get search(): string {
    const params = querystring.serialise(this._queryParams)
    return params ? `?${params}` : ''
  }

  set search(value: string) {
    this._url.search = value
    this._queryParams = querystring.deserialise(value)
  }

  toString(): string {
    return this.href.toString()
  }

  toJSON(): string {
    const json = this._url.toJSON()

    return json
  }
}

/**
 * Parses a URI string into a browser native URL object.
 */
export function parse(string: URLLike | string): URI {
  return castUrl(string)
}

/**
 * Starting from a base url which may include a host, or be root-relative,
 * appends a list of path parts to the end of the url, making sure path segments
 * are separated by only 1 slash.
 */
export function join(
  base: URLLike | string,
  ...parts: (URLLike | string | number)[]
): URI {
  const url = castUrl(base)

  const parsedParts = parts.map((part) => {
    if (typeof part === 'string') return part
    if (typeof part === 'number') return part.toString()

    if (isUrl(part)) {
      return part.pathname
    }

    throw new Error(
      `Expected url-like when joining paths. Received ${String(part)}`,
    )
  })

  url.pathname = urlJoin(url.pathname, ...parsedParts)

  return url
}

function castUrl(urlLike: URLLike | string): URI {
  if (typeof urlLike === 'string') {
    let domUrl: URL
    if (isRelativeUrl(urlLike)) {
      domUrl = new URL(urlLike, window.location.href)
    } else if (isProtocolRelativeUrl(urlLike)) {
      domUrl = new URL(`${window.location.protocol}${urlLike}`)
    } else {
      domUrl = new URL(urlLike)
    }

    return new URI(domUrl.href)
  }

  if (isUrl(urlLike)) {
    return clone(urlLike)
  }

  throw new Error(`Cannot convert to url. Received ${String(urlLike)}`)
}

export function isUrl(urlLike?: unknown): urlLike is URLLike {
  return urlLike instanceof URL || urlLike instanceof URI
}

const startsWithProtocol = /^[a-z]*:?\/\//
function isRelativeUrl(string: string): boolean {
  return !startsWithProtocol.test(string)
}

function isProtocolRelativeUrl(string: string): boolean {
  return string.startsWith('//')
}

function clone(url: URLLike): URI {
  return new URI(url.href)
}

function fixPolyfillEmptyQuery(href: string): string {
  return href
    .replace(/\?$/, '') // trailing ?
    .replace(/\?#/, '') // trailing ? before a hash
}
