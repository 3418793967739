import { classNames } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { mapBy, sort } from '@ember/object/computed'
import Component from '@ember/component'
import colourPicker from 'district-ui-client/utils/giraffe/colour-picker'

/**
 * Displays a column chart of books read by level
 *
 * @class BooksReadByLevelComponent
 * @extends Ember.Component
 * @property {Array} data - The data used to render the chart
 * @property {Array} data.books_read_by_level - The data object namespace
 */
@classNames('h-full', 'giraffe-dashboard-tile', 'giraffe-dashboard-books-read-by-level')
export default class ByLevel extends Component {
  /**
   * The title of the chart
   *
   * @type {String}
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  title = 'Books read by level'

  /**
   * The sort properties for the chart data
   * @type {Array}
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  sortProperties = ['range:asc']

  /**
   * An array of books read by level lexiles sorted by range
   *
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  @sort('data.books_read_by_level', 'sortProperties') sortedData

  /**
   * An array of ranges extraced from the data e.g. ['0-300', '300-500', ...]
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  @mapBy('sortedData', 'range') categories

  /**
   * An array of count values extracted from the data e.g. [19789, 66990, ...]
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  @mapBy('sortedData', 'count') seriesData

  /**
   * The series data in a format expected by the chart primitive
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  @computed('seriesData')
  get chartData() {
    return [{ data: this.seriesData }]
  }

  /**
   * The product the chart data represents.
   *
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  product = null

  /**
   * The colour to use for the chart columns
   *
   * @instance
   * @memberOf BooksReadByLevelComponent
   */
  @computed('product')
  get colour() {
    const { product } = this
    return colourPicker(product, 'bookLevelDistribution')
  }
}
