import Component from '@glimmer/component'
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import colors from '@blakeelearning/blake-colours/colours'

export default class ChartsDrivingTestsTotals extends Component {
  get summaryStats() {
    const summaryData = this.args.data?.summary ?? []
    const totalCount = summaryData.find((d) => d.category === 'total_count').count
    const studentCount = summaryData.find((d) => d.category === 'student_count').count
    const average = basicAverage(totalCount, studentCount)

    return [
      { label: 'Driving Tests Mastered', value: formatNumber(totalCount, 1) },
      { label: 'Students', value: formatNumber(studentCount, 1) },
      { label: 'Tests per Student', value: formatNumber(average, 1) },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get columns() {
    const monthData = this.args.data?.months ?? []

    const colorMap = {
      re: colors.stormBlue300,
      maths: colors.oceanyBlue300,
    }

    return [{ name: 'count', data: monthData.map((d) => d.count), color: colorMap[this.args.product] }]
  }

  /**
   * Computed list of month data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get months() {
    return this.args.data?.months?.map((m) => convertDateFormat(m.month)) ?? []
  }
}
