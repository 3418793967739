import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the lexile_history endpoint from the student-events API
 *
 * @param {String} product - The product this request is for
 * @param {Object} studentScope - The studentScope this request is for
 * @param {String} studentScope.scope -  The scope type, e.g. students, district
 * @param {Array} studentScope.ids - A list of scope type ids to query
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or null if data is empty
 */
export default function lexileDetailsHistory(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('lexile_history', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { lexile_history: history } = data

    if (isEmpty(history)) {
      return null
    }

    return {
      lexile_history: history.map(
        ({ starting_lexile: min, max_lexile: max, growth, student_id }) => {
          const initial = max - growth

          return { student_id, min, max, initial, growth }
        },
      ),
    }
  })
}
