import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'

export default class IndexRoute extends Route {
  @service
  studentEvents

  async model() {
    const product = 'rex'
    const { uiScope: scope, statsScope, period, scopedData } = this.modelFor('reporting.ui-scope')

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product, scope, period) {
    const { studentEvents } = this

    return RSVP.hash({
      totalUsageOverTime: studentEvents.totalUsageOverTime(product, scope, period),
      courseProgressByGrade: studentEvents.courseProgressByGrade(product, 'my_lessons', scope, period),
      assessmentScores: studentEvents.assessmentScores(product, scope, period),
      lexileGrowth: studentEvents.lexileGrowth(product, scope, period),
      spellingCourseProgress: studentEvents.courseProgressByGrade(product, 'spelling', scope, period),
      workingAtGradeLevel: studentEvents.workingAtGradeLevel(product, scope, period),
      booksReadByGenre: studentEvents.booksReadByGenre(product, scope, period),
      booksReadByLevel: studentEvents.booksReadByLevel(product, scope, period),
      stadiumEvents: studentEvents.stadiumEvents(product, scope, period),
      awardsEarned: studentEvents.awardsEarned(product, scope, period),
    })
  }
}
