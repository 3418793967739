import { isEmpty } from '@ember/utils'
import { A } from '@ember/array'
import { Promise } from 'rsvp'

/**
 * Fetches the driving_tests_by_grade endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {String} precinct - The precinct needed to fetch the correct data
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function drivingTests(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('driving_tests_by_grade', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const grades = data.driving_tests_by_grade
    const allPercentagesZero = A(grades).every(
      (grade) => grade.quiz_completed_percentage === 0,
    )

    if (grades.length === 0 || allPercentagesZero) {
      return null
    }

    return data
  })
}
