import { isEmpty } from '@ember/utils'

/**
 * Fetches the driving_tests_history endpoint from the student-events API
 *
 * @param {String} product - The product this request is for
 * @param {Object} studentScope - The studentScope this request is for
 * @param {String} studentScope.scope -  The scope type, e.g. students, district
 * @param {Array} studentScope.ids - A list of scope type ids to query
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or null if data is empty
 */
export default async function drivingTestsHistory(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl('driving_tests_history', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const data = await this.fetchData(url, query)
  return isEmpty(data?.driving_tests_history)
    ? null
    : data.driving_tests_history
}
