import Component from '@glimmer/component'
import { service } from '@ember/service'
import { isEmpty } from '@ember/utils'
import { reportingLegend, reportingPlotLine } from 'district-ui-client/utils/giraffe/highcharts-config'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import {
  gradePositionFromLesson,
  lessonRangeFromGradePosition,
} from 'district-ui-client/utils/giraffe/lesson-grade-map'
import colors from '@blakeelearning/blake-colours/colours'

/**
 * Format the value based on the current precinct.
 */
function valueFormatter(value, product, precinct) {
  if (product === 'rex' && precinct === 'spelling') {
    const maxLessons = 36
    const grade = Math.ceil(value / maxLessons)
    const lesson = `0${value % maxLessons}`.slice(-2).replace('00', maxLessons.toString())

    return `${grade}.${lesson}`
  }
  return value
}

const colorGradeMap = {
  0: colors.watermelonyRed300,
  1: colors.grapeyGreen400,
  2: colors.oceanyBlue300,
  3: colors.juicyOrange300,
  4: colors.purplyPink300,
  5: colors.watermelonyRed300,
  6: colors.oceanyBlue400,
  7: colors.yolkyYellow300,
  8: colors.oceanyBlue500,
  9: colors.grapeyGreen300,
}

/**
 * Puts together all the pieces for the course progress averages chart.
 *
 * @class CourseProgressAveragesComponent
 * @extends Ember.Component
 * @property {String} title - The chart title.
 * @property {Object} data - The data used to render the chart
 * @property {Number} data.grade_position - The grade position
 * @property {String} [variant] - Used to specify the visual variant to use. Defaults to 'default'.
 * @property {Object[]} data.months - The data for each month
 * @property {Object} data.months[].avg_max_lesson - The average max lesson for this month
 * @property {Object} data.months[].month - The month this data represents
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   months: [
 *     {
 *       avg_max_lesson: 50,
 *       month: '2016-07',
 *     },
 *     {
 *       avg_max_lesson: 70,
 *       month: '2016-08',
 *     },
 *   ],
 * }
 * ```
 */
export default class ChartsCourseProgressAverages extends Component {
  @service gradeSets

  get hyphenatedTitle() {
    const mainChartTitle = this.args.title
    const gradePosition = this.args.data.grade_position
    if (typeof gradePosition !== 'number') return `${mainChartTitle}`
    const gradeSuffix = this.gradeSets.findByPosition(gradePosition)?.fullName
    return `${mainChartTitle} - ${gradeSuffix}`
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get chartData() {
    const gradePosition = this.args.data.grade_position
    // if grade position beyond colors in map, loop back around to first color
    const color = colorGradeMap[gradePosition % Object.keys(colorGradeMap).length]

    const data =
      this.args.data.months?.map((monthData, index) => ({ x: index, y: monthData.avg_max_lesson, monthData })) ?? []
    return [{ name: 'avg_max_lesson', color, data }]
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data.months?.map((m) => convertDateFormat(m.month)) ?? []
  }

  get legend() {
    return reportingLegend('left', 'top', 50, 10)
  }

  /**
   * Plot lines to mark the grade bands for the lesson values.
   */
  get plotLines() {
    const { product, precinct } = this.args
    const months = this.args.data.months ?? []
    const lessons = months.map((m) => m.avg_max_lesson).filter((avgMaxLesson) => !isEmpty(avgMaxLesson))
    if (isEmpty(lessons)) return []

    const grades = lessons.map((lesson) => gradePositionFromLesson(product, precinct, lesson))
    // Add the _next_ grade to the list
    grades.push(grades[grades.length - 1] + 1)

    return grades
      .map((grade) => {
        const { min: lesson } = lessonRangeFromGradePosition(product, precinct, grade)

        if (isEmpty(lesson)) {
          return null
        }
        const label = this.gradeSets.findByPosition(grade)?.fullName ?? ''

        return reportingPlotLine(lesson, label)
      })
      .filter((plotLine) => !isEmpty(plotLine))
  }

  /**
   * Returns a formatter function to pass to Highcharts to format the tooltip label.
   */
  get toolTipFormatter() {
    const { product, precinct } = this.args

    return function () {
      return `<strong>${valueFormatter(this.y, product, precinct)}</strong>`
    }
  }

  /**
   * Returns a formatter function to pass to Highcharts to format the y axis label.
   */
  get yAxisLabelFormatter() {
    const { product, precinct } = this.args

    return function () {
      return valueFormatter(this.value, product, precinct)
    }
  }

  /**
   * Set the yRange of the chart to include all lessons for all grades in the data
   */
  get yRange() {
    const { product, precinct } = this.args
    const months = this.args.data.months ?? []
    const grades = months.reduce((acc, monthData) => {
      if (monthData.avg_max_lesson) {
        const grade = gradePositionFromLesson(product, precinct, monthData.avg_max_lesson)
        return acc.includes(grade) ? acc : [...acc, grade]
      }
      return acc
    }, [])
    const allLessons = grades
      .map((grade) => lessonRangeFromGradePosition(product, precinct, grade))
      .reduce((prev, curr) => {
        const { min, max } = curr
        return prev.concat(min, max)
      }, [])
      .sort((a, b) => a - b)

    if (isEmpty(allLessons)) return [-Infinity, Infinity]
    // add one lesson to ensure the next grade plotLine is shown for context
    allLessons.push(allLessons[allLessons.length - 1] + 1)

    return [allLessons[0], allLessons[allLessons.length - 1] + 1]
  }
}
