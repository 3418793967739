import { A } from '@ember/array'
import { gradePositionFromLexile, lexileRangeFromGradePosition } from './lexile-grade-map'

/**
 * Return the min and max lexile to be used as the yAxis Range for charts.
 * When given a range of lexiles this util should return a min or max such
 * that it contains the min max of at least one grade range. The min or max
 * will either end on a min/max of a grade (from lexileGradeMap)
 * or be to the nearest hundred of lowest/highest lexile in the given range.
 *
 * @memberOf LexileYRange
 * @param  {String} product - The product to lookup values for
 * @param  {Array} lexiles - The range of lexile values to find the min and max for.
 */
export default function lexileYRange(product, lexiles) {
  const lexilesWithNullRemoved = A(lexiles).compact()
  if (lexilesWithNullRemoved.length === 0) return [-Infinity, Infinity]
  const minLexile = Math.min(...lexilesWithNullRemoved)
  const maxLexile = Math.max(...lexilesWithNullRemoved)
  const minLexileWithPadding = floorToNearestHundred(minLexile)
  const maxLexileWithPadding = ceilingToNearestHundred(maxLexile)
  const lowestGrade = gradePositionFromLexile(product, minLexile)
  const highestGrade = gradePositionFromLexile(product, maxLexile)
  const minLexileFromLowestGrade = lexileRangeFromGradePosition(product, lowestGrade).min
  const maxLexileFromHighestGrade = lexileRangeFromGradePosition(product, highestGrade).max
  const mins = A([minLexileWithPadding, minLexileFromLowestGrade]).compact()
  const maxes = A([maxLexileWithPadding, maxLexileFromHighestGrade]).compact()
  const absoluteMin = Math.min(...mins)
  const absoluteMax = Math.max(...maxes)
  return [absoluteMin, absoluteMax]
}

function ceilingToNearestHundred(number) {
  // adding one to guarantee min and mx are not the same
  return Math.ceil((number + 1) / 100) * 100
}

function floorToNearestHundred(number) {
  return Math.floor(number / 100) * 100
}
