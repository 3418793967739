import { A } from '@ember/array'

export default function (data, summaryKey, dataKey, dataType = 'months') {
  const byGrade = groupByGradePosition(data, summaryKey, dataKey)
  return pullKeyOutOfGradePositionData(byGrade, dataKey, dataType)
}

export function groupByGradePosition(data, summaryKey, dataKey) {
  const summaries = A(data[summaryKey])
    .sortBy('grade_position')
    .filter((el) => el.grade_position !== null)
  const dataItems = A(data[dataKey])
    .sortBy('grade_position')
    .filter((el) => el.grade_position !== null)
  return summaries.map((summary, i) => ({
    grade_position: summary.grade_position,
    summary: summary.data,
    [dataKey]: dataItems[i],
  }))
}

export function pullKeyOutOfGradePositionData(grades, dataKey, keyToPull) {
  return grades.map((grade) => ({
    grade_position: grade.grade_position,
    summary: grade.summary,
    [keyToPull]: grade[dataKey][keyToPull],
  }))
}
