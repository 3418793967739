/**
 * Flattens the summary data from an array to an object.
 * The category value becomes the key, the value of total_count becomes the value
 *
 * @param {Array} SummaryData - An array of objects with category and total count
 *                              as keys.
 * @return {Object} FlattenedData
 *
 * Input Format
 * ```
 * [
 *   { category: 'my_category', total_count: 4 }
 * ]
 * ```
 *
 * Returned Format
 * ```
 * { my_category: 4 }
 * ```
 */
export default function flattenSummaryData(summary) {
  return summary.reduce((acc, row) => {
    acc[row.category] = row.count
    return acc
  }, {})
}
