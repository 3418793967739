import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the simple_summary endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function simpleSummary(product, studentScope, period, teachers) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)
  const url = this.buildUrl('simple_summary', product, studentScope)
  const query = this.buildQuery(studentScope, period, teachers)

  return this.fetchData(url, query).then((data) => {
    const { simple_summary: summary } = data

    if (studentScope.scope === 'students' && isEmpty(summary.students)) {
      return null
    }
    if (studentScope.scope === 'school' && isEmpty(summary.teachers)) {
      return null
    }
    if (studentScope.scope === 'district' && isEmpty(summary.schools)) {
      return null
    }
    return data
  })
}
