import { isEmpty } from '@ember/utils'
import { Promise as EmberPromise } from 'rsvp'

/**
 * Fetches the lessons_events_summary endpoint from the student-events API
 *  * Sorts the lessons_events array by lesson_number
 *  * Fills in holes to complete the data as sets of maps (5 lessons)
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function lessonsEventsSummary(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return EmberPromise.resolve(null)

  const url = this.buildUrl('lessons_events_summary', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { lessons_events_summary: summaryData, lessons_events: eventsData } =
      data

    if (
      isEmpty(eventsData) ||
      isEmpty(summaryData) ||
      isEmpty(summaryData.data)
    ) {
      return null
    }

    return {
      lessons_events_summary: summaryData,
      lessons_events: [...eventsData],
    }
  })
}
