import { isEmpty } from '@ember/utils'

/**
 * Fetches the mental_minute_summary endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default async function mentalMinuteSummary(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl('mental_minute_summary', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const data = await this.fetchData(url, query)
  if (!data || !data.mental_minute_summary) return null

  const {
    mental_minute_summary: { summary, aggregate },
  } = data

  // Ensure the response returns zero's by default for all category data items. If the response has no data at all,
  // then return null so that gizmo is shown
  let summaryDataExists = false
  const newSummary = {}
  if (summary) {
    const categories = ['addition', 'subtraction', 'multiplication', 'division']
    const defaultCategoryData = {
      sprints_mastered: 0,
      correct_answers: 0,
      improvement: 0,
    }
    const categoryDataKeys = Object.keys(defaultCategoryData)

    categories.forEach((category) => {
      const categoryData = summary[category] || {}
      summaryDataExists =
        summaryDataExists ||
        categoryDataKeys.some((dataKey) => categoryData[dataKey] > 0)
      newSummary[category] = { ...defaultCategoryData, ...categoryData }
    })
  }
  let aggregateDataExists = false
  const newAggregate = {}
  if (aggregate) {
    newAggregate.sprints_mastered = {
      total: aggregate.sprints_mastered?.total || 0,
    }
    newAggregate.correct_answers = {
      total: aggregate.correct_answers?.total || 0,
    }
    newAggregate.improvement = { average: aggregate.improvement?.average || 0 }

    aggregateDataExists =
      newAggregate.sprints_mastered.total > 0 ||
      newAggregate.correct_answers.total > 0 ||
      newAggregate.improvement.average > 0
  }
  // Must have data in both summary and aggregate, not one or the other
  return summaryDataExists && aggregateDataExists
    ? { summary: newSummary, aggregate: newAggregate }
    : null
}
