import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import colors from '@blakeelearning/blake-colours/colours'

/**
 * Puts together all the pieces for the storylands totals chart.
 *
 * @class StorylandsLessonsTotals
 * @extends Ember.Component
 * @property {String} [title] - The chart title
 * @property {String} [grade] - The grade the chart represents
 * @property {Object} data - The data used to render the chart
 * @property {Number} data.grade_position - The grade position
 * @property {Object[]} data.summary - The summary of the months data
 * @property {String} data.summary[].category - The category of the summary data
 * @property {Number} data.summary[].count - The number of lessons completed
 * @property {Number} data.summary[].average - The average score of completed lessons
 * @property {Object[]} data.months - The data for each month
 * @property {Number} data.months[].count - The number of lessons completed
 * @property {String} data.months[].month - The month this data represents
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'total_count', count: 50 },
 *     { category: 'average_score', average: 78 },
 *   ],
 *   months: [
 *     {
 *       month: '2017-01',
 *       count: 20,
 *     },
 *     {
 *       month: '2017-02',
 *       count: 15,
 *     },
 *   ],
 * },
 * ```
 */
export default class ChartsStorylandsLessonsTotals extends Component {
  @service intl

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total lessons completed and average score per student.
   */
  get summaryStats() {
    const summaryData = this.args.data?.summary ?? []
    const total = summaryData.find((d) => d.category === 'total_count').count
    const averageScore = summaryData.find((d) => d.category === 'average_score').average

    return [
      { label: this.intl.t('reporting.summaryTitles.storylandsTotals.total'), value: formatNumber(total, 0) },
      { label: this.intl.t('reporting.summaryTitles.storylandsTotals.average'), value: `${averageScore}%` },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get columns() {
    const monthsData = this.args.data?.months ?? []
    const color = colors.juicyOrange300

    const data = monthsData.map((monthData, index) => ({ x: index, y: monthData.count, monthData }))

    return [{ name: 'count', color, data }]
  }

  get months() {
    return this.args.data?.months?.map((m) => convertDateFormat(m.month)) ?? []
  }

  /**
   * A formatter function to pass to Highcharts to format the tooltip label.
   */
  toolTipFormatter() {
    return `${this.point.monthData.count}`
  }
}
