import Service from '@ember/service'
import { getConfig } from '@blakeelearning/get-config'
import { URI, join } from '../uri.ts'

const startsWithProtocol = /^[a-z]*:?\/\//

/**
 * Service for helping with asset urls
 */
export default class Assets extends Service {
  /**
   * Wrapper around the assetUrl util, to combine a url with the asset host
   *
   * @param source The url string to be joined to the asset host
   * @param assetHost Optionally pass in an asset host string
   * @return the joined asset url string
   */
  joinUrl(source: string, assetHost?: string): URI {
    if (startsWithProtocol.test(source)) {
      return new URI(source)
    }

    assetHost = assetHost ?? getConfig(this, 'APP.assetHost', '')

    return join(assetHost, source)
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    assets: Assets
  }
}
