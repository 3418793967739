import Service from '@ember/service'
import { assert } from '@ember/debug'
import { tracked } from '@glimmer/tracking'
import * as uri from '@blakeelearning/uri/uri'
import { format } from 'date-fns'

import assessmentScores from './fetchers/assessment-scores'
import assessmentScoresAverages from './fetchers/assessment-scores-averages'
import assessmentScoresHistory from './fetchers/assessment-scores-history'
import assessmentScoresStudent from './fetchers/assessment-scores-student'
import assessmentScoresTotals from './fetchers/assessment-scores-totals'
import assignmentScores from './fetchers/assignment-scores'
import awardsEarned from './fetchers/awards-earned'
import awardsEarnedByMonthEvents from './fetchers/awards-earned-by-month-events'
import awardsEarnedHistory from './fetchers/awards-earned-history'
import awardsEarnedTotals from './fetchers/awards-earned-totals'
import booksReadByGenre from './fetchers/books-read-by-genre'
import booksReadByGenreHistory from './fetchers/books-read-by-genre-history'
import booksReadByGenreTotals from './fetchers/books-read-by-genre-totals'
import booksReadByLevel from './fetchers/books-read-by-level'
import booksReadByLevelHistory from './fetchers/books-read-by-level-history'
import booksReadByLevelTotals from './fetchers/books-read-by-level-totals'
import booksReadStudent from './fetchers/books-read-student'
import courseProgressAverages from './fetchers/course-progress-averages'
import courseProgressByGrade from './fetchers/course-progress-by-grade'
import courseProgressCategories from './fetchers/course-progress-categories'
import courseProgressHistory from './fetchers/course-progress-history'
import courseProgressStudent from './fetchers/course-progress-student'
import courseProgressTotals from './fetchers/course-progress-totals'
import drivingTests from './fetchers/driving-tests'
import drivingTestDetailsTotalsByMonth from './fetchers/driving-test-details-totals-by-month'
import drivingTestDetailsHistory from './fetchers/driving-test-details-history'
import drivingTestsHistory from './fetchers/driving-tests-history'
import drivingTestsStudent from './fetchers/driving-tests-student'
import drivingTestsTotals from './fetchers/driving-tests-totals'
import drivingTestsTotalsByGradeByMonth from './fetchers/driving-tests-totals-by-grade-by-month'
import lessonsEventsSummary from './fetchers/lessons-events-summary'
import lexileDetailsAverages from './fetchers/lexile-details-averages'
import lexileDetailsHistory from './fetchers/lexile-details-history'
import lexileDetailsStudent from './fetchers/lexile-details-student'
import lexileGrowth from './fetchers/lexile-growth'
import mentalMinuteOverview from './fetchers/mental-minute-overview'
import mentalMinuteSchoolOverview from './fetchers/mental-minute-school-overview'
import mentalMinuteStudent from './fetchers/mental-minute-student'
import mentalMinuteSummary from './fetchers/mental-minute-summary'
import overview from './fetchers/overview'
import peaksProgress from './fetchers/peaks-progress'
import peaksProgressDetails from './fetchers/peaks-progress-details'
import quizScoresAverages from './fetchers/quiz-scores-averages'
import quizScoresHistory from './fetchers/quiz-scores-history'
import quizScoresStudent from './fetchers/quiz-scores-student'
import quizScoresTotals from './fetchers/quiz-scores-totals'
import readingMapDetails from './fetchers/reading-map-details'
import simpleSummary from './fetchers/simple-summary'
import skillsMastered from './fetchers/skills-mastered'
import spellingLessonDetails from './fetchers/spelling-lesson-details'
import spellingMapDetails from './fetchers/spelling-map-details'
import stadiumEvents from './fetchers/stadium-events'
import stadiumEventsCategories from './fetchers/stadium-events-categories'
import stadiumEventsHistory from './fetchers/stadium-events-history'
import stadiumEventsStudent from './fetchers/stadium-events-student'
import stadiumEventsTotals from './fetchers/stadium-events-totals'
import storiesWrittenByGrade from './fetchers/stories-written-by-grade'
import storiesWrittenDetails from './fetchers/stories-written-details'
import storiesWrittenHistory from './fetchers/stories-written-history'
import storiesWrittenStudent from './fetchers/stories-written-student'
import storiesWrittenTotals from './fetchers/stories-written-totals'
import storylandsHistory from './fetchers/storylands-history'
import storylandsStudent from './fetchers/storylands-student'
import storylandsTotalsByGradeByMonth from './fetchers/storylands-totals-by-grade-by-month'
import summary from './fetchers/summary'
import studentSummary from './fetchers/student-summary'
import timeOnTaskHistory from './fetchers/time-on-task-history'
import timeOnTaskHistoryV2 from './fetchers/time-on-task-history-v2'
import timeOnTaskUsage from './fetchers/time-on-task-usage'
import timeOnTaskUsageV2 from './fetchers/time-on-task-usage-v2'
import totalUsageOverTime from './fetchers/total-usage-over-time'
import workingAtGradeLevel from './fetchers/working-at-grade-level'
import workingAtGradeLevelTotalsByGrade from './fetchers/working-at-grade-level-totals-by-grade'

/**
 * Service for interacting with the student-events api.
 *
 * @class StudentEventsService
 */
export default class extends Service {
  @tracked setupRequestInit = () => ({})

  /**
   * Should be set to a string like https://api.readingeggs.com/student-events/api/v3/ depending on the environment.
   * This will be joined with the scope and fetcher endpoint for making requests.
   */
  baseUrl = ''

  /**
   * Builds a url from a product, studentScope and endpoint.
   *
   * @private
   * @instance
   * @memberOf StudentEventsService
   *
   * @param {String} endpoint - The endpoint uri fragment
   * @param {String} product - The product, e.g., rex
   * @param {Object} studentScope - The studentScope object
   * @param {String} studentScope.scope - The scope's type, e.g. 'students', 'district'
   * @return {String} The url
   */
  buildUrl(endpoint, product, studentScope) {
    assert(
      'studentEvents baseUrl must be set before making requests',
      this.baseUrl,
    )
    const underscoredProduct =
      product === 'fast-phonics' ? 'fast_phonics' : product
    const joined = uri.join(
      this.baseUrl,
      studentScope.scope,
      underscoredProduct,
      endpoint,
    )
    return joined.href
  }

  /**
   * Builds a query object that is suitable for requests targetting the
   * student-events api from studentScope, period and base query objects.
   *
   * @private
   * @instance
   * @memberOf StudentEventsService
   *
   * @param {Object} studentScope - The studentScope object
   * @param {Array} studentScope.ids - A list of scope type ids to query
   * @param {Object} period - The period object
   * @param {Date} period.start - The start date for this query
   * @param {Date} period.end - The end date for this query
   * @param {Object} [base={}] - The base query object to start from
   * @return {Object} The new query object to use, that can be used as a fetch requestinit
   */
  buildQuery({ ids }, { start, end }, base = {}) {
    const fromTime = new Date(start)
    fromTime.setHours(0, 0, 0, 0) // start of day
    const fromTimeSeconds = Math.floor(fromTime.getTime() / 1000) // seconds since epoch

    const toTime = new Date(end)
    toTime.setHours(23, 59, 59, 999) // end of day
    const toTimeSeconds = Math.floor(toTime.getTime() / 1000) // seconds since epoch

    const tz = format(fromTime, 'xxx') // formats timezone offset like `+11:30`. For GMT, it's `+00:00`

    const data = {
      ...base,
      from_date: fromTimeSeconds,
      to_date: toTimeSeconds,
      tz,
      ids,
    }
    return {
      body: JSON.stringify(data),
      headers: { 'content-type': 'application/json' },
    }
  }

  /**
   * Fetches data from an API endpoint.
   *
   * @private
   * @instance
   * @memberOf StudentEventsService
   *
   * @param {RequestInfo | URL} input - The URL of the API endpoint to fetch data from.
   * @param {RequestInit | undefined} requestInit - options object for fetch
   * @return {Promise} An RSVP Promise that resolves with the returned data.
   */
  async fetchData(input, requestInit = {}) {
    try {
      // make sure to merge the headers. (note: ...spreading undefined is valid)
      const standardRequestInit = this.setupRequestInit()
      const mergedRequestInit = {
        ...standardRequestInit,
        ...requestInit,
        headers: { ...standardRequestInit.headers, ...requestInit.headers },
      }
      const response = await fetch(input, {
        method: 'POST',
        ...mergedRequestInit,
      })

      if (response.ok) {
        return await response.json()
      }
      if (response.headers.get('content-type') === 'application/json') {
        throw await response.json()
      }
      throw await response.text()
    } catch (error) {
      if (error instanceof DOMException && error?.name === 'AbortError') {
        // Fetch aborted by user action, eg closed tab, stop button, network outage. Do nothing.
        return
      }
      console.error('Couldnt load the student events report', error)
      throw error
    }
  }

  assessmentScores = assessmentScores

  assessmentScoresAverages = assessmentScoresAverages

  assessmentScoresHistory = assessmentScoresHistory

  assessmentScoresStudent = assessmentScoresStudent

  assessmentScoresTotals = assessmentScoresTotals

  assignmentScores = assignmentScores

  awardsEarned = awardsEarned

  awardsEarnedByMonthEvents = awardsEarnedByMonthEvents

  awardsEarnedHistory = awardsEarnedHistory

  awardsEarnedTotals = awardsEarnedTotals

  booksReadByGenre = booksReadByGenre

  booksReadByGenreHistory = booksReadByGenreHistory

  booksReadByGenreTotals = booksReadByGenreTotals

  booksReadByLevel = booksReadByLevel

  booksReadByLevelHistory = booksReadByLevelHistory

  booksReadByLevelTotals = booksReadByLevelTotals

  booksReadStudent = booksReadStudent

  courseProgressAverages = courseProgressAverages

  courseProgressByGrade = courseProgressByGrade

  courseProgressCategories = courseProgressCategories

  courseProgressHistory = courseProgressHistory

  courseProgressStudent = courseProgressStudent

  courseProgressTotals = courseProgressTotals

  drivingTests = drivingTests

  drivingTestDetailsTotalsByMonth = drivingTestDetailsTotalsByMonth

  drivingTestDetailsHistory = drivingTestDetailsHistory

  drivingTestsHistory = drivingTestsHistory

  drivingTestsStudent = drivingTestsStudent

  drivingTestsTotals = drivingTestsTotals

  drivingTestsTotalsByGradeByMonth = drivingTestsTotalsByGradeByMonth

  peaksProgress = peaksProgress

  peaksProgressDetails = peaksProgressDetails

  lessonsEventsSummary = lessonsEventsSummary

  lexileDetailsAverages = lexileDetailsAverages

  lexileDetailsHistory = lexileDetailsHistory

  lexileDetailsStudent = lexileDetailsStudent

  lexileGrowth = lexileGrowth

  mentalMinuteOverview = mentalMinuteOverview

  mentalMinuteSchoolOverview = mentalMinuteSchoolOverview

  mentalMinuteStudent = mentalMinuteStudent

  mentalMinuteSummary = mentalMinuteSummary

  overview = overview

  quizScoresAverages = quizScoresAverages

  quizScoresHistory = quizScoresHistory

  quizScoresStudent = quizScoresStudent

  quizScoresTotals = quizScoresTotals

  readingMapDetails = readingMapDetails

  simpleSummary = simpleSummary

  skillsMastered = skillsMastered

  spellingLessonDetails = spellingLessonDetails

  spellingMapDetails = spellingMapDetails

  stadiumEvents = stadiumEvents

  stadiumEventsCategories = stadiumEventsCategories

  stadiumEventsHistory = stadiumEventsHistory

  stadiumEventsStudent = stadiumEventsStudent

  stadiumEventsTotals = stadiumEventsTotals

  storiesWrittenByGrade = storiesWrittenByGrade

  storiesWrittenDetails = storiesWrittenDetails

  storiesWrittenHistory = storiesWrittenHistory

  storiesWrittenStudent = storiesWrittenStudent

  storiesWrittenTotals = storiesWrittenTotals

  storylandsTotalsByGradeByMonth = storylandsTotalsByGradeByMonth

  summary = summary

  studentSummary = studentSummary

  timeOnTaskHistory = timeOnTaskHistory

  timeOnTaskHistoryV2 = timeOnTaskHistoryV2

  timeOnTaskUsage = timeOnTaskUsage

  timeOnTaskUsageV2 = timeOnTaskUsageV2

  storylandsHistory = storylandsHistory

  storylandsStudent = storylandsStudent

  totalUsageOverTime = totalUsageOverTime

  workingAtGradeLevel = workingAtGradeLevel

  workingAtGradeLevelTotalsByGrade = workingAtGradeLevelTotalsByGrade
}
