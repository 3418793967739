import { format, isBefore } from 'date-fns'

/**
 * Fills missing month objects in an array of month objects. Takes a period to
 * fill from and to, a template for what an empty month object looks like, and
 * optionally a format for the month values.
 *
 * It strips months that don't land inside the period.
 *
 * The period bounds are inclusive.
 *
 * monthData would look like this:
 *
 * [
 *   {
 *     month: '2012-05',
 *     anything: 'else',
 *     that: 'you',
 *     want: 0,
 *   },
 *   {
 *     month: '2012-07',
 *     anything: 'a',
 *   },
 * ]
 *
 * A template would look like { anything: null, that: null, want: 'c'}
 *
 * @example
 *   fillMonths(monthData, period, { foo: null, bar: null })
 *
 * @param  {Array} monthData - List of month objects.
 * @param  {Object} period - A period from and to
 * @param  {Date} period.start - Where to start the range from
 * @param  {Date} period.end - Where to take the range to
 * @param  {Object} template - The template for month data. Missing months will be filled with this
 *                             value, and existing months will have their data merged to ensure a
 *                             consistent set of object keys.
 * @return {Array} The month range requested with empty months filled in
 */
export default function fillMonths(monthData, { start, end }, template = {}) {
  const formatString = 'yyyy-MM'
  const startDate = new Date(start)
  const endDate = new Date(end)

  const months = []
  // While the start date month is before or equal to the end date month
  while (
    isBefore(startDate, endDate) ||
    startDate.getMonth() === endDate.getMonth()
  ) {
    const formattedMonth = format(startDate, formatString)
    const currentMonthData =
      monthData.find((monthObject) => monthObject.month === formattedMonth) ||
      {}

    months.push({ ...template, ...currentMonthData, month: formattedMonth })

    startDate.setMonth(startDate.getMonth() + 1) // going past december will rollover to the next year.
  }

  return months
}
