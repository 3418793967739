import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the course_progress_categories endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {String} precinct - The precinct needed to fetch the correct data
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function courseProgressCategories(
  product,
  precinct,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('course_progress_categories', product, studentScope)
  const query = this.buildQuery(studentScope, period, { precinct })

  return this.fetchData(url, query).then((data) => {
    const order = [
      'literal',
      'inferential',
      'vocabulary_and_usage',
      'text_analysis_and_critical_literacy',
    ]
    const { course_progress_categories } = data
    // Sort the data by the categories, as they appear in the order array above.
    const sortedData = course_progress_categories.sort((a, b) => {
      const aIndex = order.indexOf(a.category)
      const bIndex = order.indexOf(b.category)
      if (aIndex > bIndex) return 1
      if (aIndex < bIndex) return -1
      return 0
    })

    if (sortedData.length === 0) {
      return null
    }
    return { course_progress_categories: sortedData }
  })
}
