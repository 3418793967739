import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import colors from '@blakeelearning/blake-colours/colours'

const colorMap = {
  re: {
    reading: colors.oceanyBlue300,
    spelling: colors.grapeyGreen300,
  },
  rex: {
    my_lessons: colors.oceanyBlue300,
    spelling: colors.grapeyGreen300,
  },
  maths: {
    lessons: colors.forestGreen300,
  },
}

/**
 * Turns a student-events course_progress_by_grade response into a giraffe chart
 * using the dashboard-title and series-bar primitives.
 *
 * Data format:
 *
 * ```
 * {
 *   course_progress_by_grade: {
 *     grades: [
 *       {
 *         grade_position: 0,
 *         lesson_count: 10,
 *         student_count: 10,
 *       },
 *       {
 *         grade_position: 1,
 *         lesson_count: 20,
 *         student_count: 10,
 *       },
 *     ],
 *     student_count: 20,
 *   }
 * }
 * ```
 * @class DashboardCourseProgressComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.course_progress_by_grade - Number of lessons completed and student count for each grade
 * @property {Number} data.course_progress_by_grade[].grade_position - The position number for the grade
 * @property {Number} data.course_progress_by_grade[].student_count - The number of students
 * @property {String} product - The product the chart is being used in.
 * @property {String} precinct - The precinct the chart is representing progress for
 * @property {String} [title] - The chart title
 */
export default class ChartsDashboardCourseProgress extends Component {
  @service gradeSets

  /**
   * The series labels for the chart, formatted for Highcharts.
   * A list of formatted grade names.
   */
  get categories() {
    return (
      this.args.data?.course_progress_by_grade?.grades?.map(
        (grade) => this.gradeSets.findByPosition(grade.grade_position)?.name ?? '-',
      ) ?? []
    )
  }

  /**
   * Bar data formatted for Highcharts
   */
  get bars() {
    const color = colorMap[this.args.product]?.[this.args.precinct]
    const byGrade = this.args.data?.course_progress_by_grade?.grades ?? []
    return [
      {
        data: byGrade.map((grade) =>
          grade.student_count === 0 ? 0 : Math.round(grade.lesson_count / grade.student_count),
        ),
        color,
      },
    ]
  }

  /**
   * Flag if data has only one grade
   */
  get singleGrade() {
    return this.args.data?.course_progress_by_grade?.grades?.length === 1
  }

  /**
   * Total number of students across all grades
   * Calculates number of contributing students
   */
  get studentCount() {
    return formatNumber(this.args.data?.course_progress_by_grade?.student_count ?? 0, 2)
  }
}
