import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type SessionService from 'district-ui-client/services/session'
import type ReportingUiScopeRoute from 'district-ui-client/routes/reporting/ui-scope'
import type { NormalizedInterval } from 'date-fns'
import type { DashboardTableSummary } from 'district-ui-client/routes/reporting/ui-scope/re/dashboard/table'

interface StatsResponse {
  summary: Nullable<{
    summary: DashboardTableSummary
  }>
}

export default class RexDashboardTableRoute extends Route {
  @service studentEvents!: StudentEventsService

  @service session!: SessionService

  async model() {
    const { period, scopedData, uiScope } = this.modelFor('reporting.ui-scope') as ModelFor<ReportingUiScopeRoute>

    // The /summary endpoint is overused and returns different data for the school scope. We need to always call it
    // with district scope
    const statsScope = { scope: 'district', ids: [this.session.currentDistrict.id] }

    const product = 'rex'
    const stats = await this.makeStatsRequest(product, statsScope, period)

    // if the scope was school, remove unrelated data from the stats.
    if (uiScope.scope === 'school' && stats.summary?.summary.schools) {
      stats.summary.summary.schools = stats.summary.summary.schools.filter(
        (schoolData) => String(schoolData.school_id) === uiScope.id,
      )
    }

    return {
      product,
      period,
      scopedSchools: scopedData.schools,
      ...stats,
    }
  }

  makeStatsRequest(
    product: string,
    statsScope: { scope: string; ids: string[] },
    period: NormalizedInterval<Date>,
  ): Promise<StatsResponse> {
    return RSVP.hash({
      summary: this.studentEvents.summary(product, statsScope, period),
    })
  }
}
