import { isEmpty } from '@ember/utils'

/**
 * Fetches the progress-details endpoint from the student-events API
 *
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default async function peaksProgressDetails({
  scope: studentScope,
  period,
}) {
  const product = 'fast_phonics'
  if (isEmpty(studentScope?.ids)) return null

  const url = this.buildUrl('progress_details', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const response = await this.fetchData(url, query)

  const isValid = validateResponse(response)
  if (!isValid) {
    // didn't pass basic validation, log error and return null
    console.error(
      'summary and students are required for fast_phonics progress_details response',
    )
    return null
  }

  const data = response.fast_phonics_progress_details
  // empty data is still valid, but return null when it happens
  return data.students.length === 0 ? null : data
}

function validateResponse(response) {
  const hasSummary =
    Object.keys(response?.fast_phonics_progress_details?.summary ?? {}).length >
    0
  const hasStudentsArray = response?.fast_phonics_progress_details?.students
  return hasSummary && hasStudentsArray
}
