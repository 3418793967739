import { A as emberArray } from '@ember/array'
import { assert } from '@ember/debug'
import emberObject, { get } from '@ember/object'
import { isEmpty } from '@ember/utils'
import _min from 'lodash/min'
import _max from 'lodash/max'
import range from 'lodash/range'

const rex = emberObject.create({
  my_lessons: {
    2: { min: 1, max: 20 },
    3: { min: 21, max: 60 },
    4: { min: 61, max: 100 },
    5: { min: 101, max: 140 },
    6: { min: 141, max: 180 },
    7: { min: 181, max: 220 },
  },
  spelling: {
    2: { min: 1, max: 36 },
    3: { min: 37, max: 72 },
    4: { min: 73, max: 108 },
    5: { min: 109, max: 144 },
    6: { min: 145, max: 180 },
    7: { min: 181, max: 216 },
  },
})

const re = emberObject.create({
  reading: {
    1: { min: 1, max: 50 },
    2: { min: 51, max: 100 },
    3: { min: 101, max: 120 },
  },
  spelling: {
    1: { min: 1, max: 32 },
    2: { min: 33, max: 64 },
    3: { min: 65, max: 96 },
  },
  storylands: {
    2: { min: 1, max: 10 },
    3: { min: 11, max: 20 },
  },
})

const maths = emberObject.create({
  lessons: {
    1: { min: 1, max: 50 },
    2: { min: 51, max: 100 },
    3: { min: 101, max: 150 },
    4: { min: 151, max: 200 },
  },
  mental_minute: {
    1: { min: 1, max: 39 },
    2: { min: 40, max: 71 },
    3: { min: 72, max: 106 },
    4: { min: 107, max: 145 },
  },
})

const data = emberObject.create({ rex, re, maths })

function isProduct(product) {
  return Object.keys(data).indexOf(product) !== -1
}

function isPrecinctOf(product, precinct) {
  const productExists = isProduct(product)
  return productExists && Object.keys(data[product]).indexOf(precinct) !== -1
}

/**
 * Lookup the max lesson number for a product and precinct.
 *
 * @memberOf LessonGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {String} precinct - The precinct to lookup values for
 * @return {Number} The max lesson number for the product and precinct.
 */
export function getMaxLessonNumber(product, precinct) {
  assert('Please pass a valid product name', isProduct(product))
  assert(`Please pass a valid precinct name for ${product}`, isPrecinctOf(product, precinct))

  const {
    [product.toLowerCase()]: { [precinct]: productData },
  } = data
  const maxGrade = _max(Object.keys(productData))
  return get(productData[maxGrade], 'max')
}

/**
 * Lookup the grade position from a lesson value for a product and precinct.
 *
 * @memberOf LessonGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {Number} lesson - The lesson value to lookup the matching grade position
 * @param  {String} precinct - The precinct to lookup values for
 * @return {Number} The grade position that matches the lesson value for the product.
 */
export function gradePositionFromLesson(product, precinct, lesson) {
  assert('Please pass a valid product name', isProduct(product))
  assert(`Please pass a valid precinct name for ${product}`, isPrecinctOf(product, precinct))

  const value = parseInt(lesson, 10)
  const {
    [product.toLowerCase()]: { [precinct]: productData },
  } = data
  const [gradePosition] = emberArray(
    Object.keys(productData).map((grade) => {
      const { min, max } = get(productData, grade)

      if (value >= min && value <= max) {
        return parseInt(grade, 10)
      }
      return null
    }),
  ).reject(isEmpty)

  return gradePosition
}

/**
 * Lookup the lesson range from a grade position for a product and precinct.
 *
 * @memberOf LessonGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {Number} grade - The grade to lookup the matching lesson range
 * @param  {String} precinct - The precinct to lookup values for
 * @return {Object} The lesson range that matches the grade position for the product
 */
export function lessonRangeFromGradePosition(product, precinct, grade) {
  assert('Please pass a valid product name', isProduct(product))
  assert(`Please pass a valid precinct name for ${product}`, isPrecinctOf(product, precinct))

  const value = parseInt(grade, 10)
  const {
    [product.toLowerCase()]: { [precinct]: productData },
  } = data

  return productData[value] || {}
}

/**
 * Pass back a filled array of inclusive lessons from an array
 * of grade positions from a product and precinct.
 *
 * @memberOf LessonGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {String} precinct - The precinct to lookup values for
 * @param  {Array} grades - The grades to lookup the matching lesson range
 * @return {Object} The lesson range that matches the grade position for the product
 */
export function lessonsFromGradePositions(product, precinct, grades) {
  assert('Please pass a valid product name', isProduct(product))
  assert(`Please pass a valid precinct name for ${product}`, isPrecinctOf(product, precinct))

  const sortedGrades = emberArray(grades).sort()
  const [minGrade, maxGrade] = [_min, _max].map((fn) => fn(sortedGrades))
  const [minGradeValue, maxGradeValue] = [minGrade, maxGrade].map((grade) => parseInt(grade, 10))
  const {
    [product.toLowerCase()]: { [precinct]: productData },
  } = data
  const [minGradeRange, maxGradeRange] = [minGradeValue, maxGradeValue].map(
    (value) => productData[value] || { min: 0, max: 0 },
  )

  const lessonRange = range(minGradeRange.min, maxGradeRange.max + 1)
  return lessonRange
}

/**
 * Pass back a filled array of lessons that spans every lesson in grades that is
 * touched by the passed in lessons.
 *
 * @memberOf LessonGradeMap
 * @param  {String} product - The product to lookup values for
 * @param  {String} precinct - The precinct to lookup values for
 * @param  {Array} lessons - The lessons to lookup the matching grades of lessons range
 * @return {Object} The lessons array that ranges the matching grade position for the product
 */
export function filledLessonsInWholeGradesFromLessons(product, precinct, lessons) {
  assert('Please pass a valid product name', isProduct(product))
  assert(`Please pass a valid precinct name for ${product}`, isPrecinctOf(product, precinct))

  const sortedLessons = emberArray(lessons).sort()
  const [minLesson, maxLesson] = [_min, _max].map((fn) => fn(sortedLessons) || 1)
  const minAndMaxGradePosition = [minLesson, maxLesson].map((lessonNumber) =>
    gradePositionFromLesson(product, precinct, lessonNumber),
  )

  return lessonsFromGradePositions(product, precinct, minAndMaxGradePosition)
}
