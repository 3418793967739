import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the overview endpoint from the student-events API
 *
 * @param {String} product - The product this request is for
 * @param {Object} studentScope - The studentScope this request is for
 * @param {String} studentScope.scope -  The scope type, e.g. students, district
 * @param {Array} studentScope.ids - A list of scope type ids to query
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or null promise if data is empty
 */
export default function overview(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('overview', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { overview: studentValues } = data

    if (isEmpty(studentValues)) {
      return null
    }
    return data
  })
}
