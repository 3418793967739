import { classNames } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { alias, map } from '@ember/object/computed'
import { A } from '@ember/array'
import Component from '@ember/component'
import { capitalize } from '@ember/string'
import colourPicker from 'district-ui-client/utils/giraffe/colour-picker'

/**
 * Display a chart of stadium events
 *
 * @class StadiumEventsComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.stadium_events - The data object namespace
 * @property {Object[]} data.stadium_events.events - The array of event objects
 * @property {String} data.stadium_events.events[].event_type - The type of the event
 * @property {Number} data.stadium_events.events[].count - The count of the event
 */
@classNames('h-full', 'giraffe-dashboard-tile', 'giraffe-dashboard-stadium-events')
export default class StadiumEvents extends Component {
  /**
   * The array of stadium events
   *
   * @instance
   * @memberOf StadiumEventsComponent
   */
  @alias('data.stadium_events') stadiumEvents

  /**
   * The array of stadium event types
   *
   * @instance
   * @memberOf StadiumEventsComponent
   */
  eventTypes = ['spelling', 'grammar', 'vocabulary', 'usage']

  /**
   * The series labels for the chart, formatted for Highcharts.
   * An array of event type names.
   *
   * @instance
   * @memberOf StadiumEventsComponent
   */
  @map('eventTypes', (eventType) => capitalize(eventType)) categories

  /**
   * The input data for the chart, formatted for Highcharts.
   *
   * @instance
   * @memberOf StadiumEventsComponent
   */
  @computed('eventTypes', 'stadiumEvents')
  get rows() {
    const { eventTypes } = this
    const { stadiumEvents } = this

    const data = eventTypes.map((eventType) => {
      return A(stadiumEvents).findBy('event_type', eventType).count
    })

    return [{ data }]
  }

  colours = colourPicker('rex', 'stadiumEvents')
}
