import { helper as buildHelper } from '@ember/component/helper'
import { isEmpty } from '@ember/utils'

export function thousands([number]) {
  if (isEmpty(number)) return '-'
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return 0
}

export default buildHelper(thousands)
