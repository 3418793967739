import { A } from '@ember/array'
import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'

/**
 * Fetches the course_progress_averages_by_grade_by_month endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {String} precinct - The precinct needed to fetch the correct data
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function courseProgressAverages(
  product,
  precinct,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl(
    'course_progress_averages_by_grade_by_month',
    product,
    studentScope,
  )
  const query = this.buildQuery(studentScope, period, { precinct })

  return this.fetchData(url, query).then((data) => {
    const {
      course_progress_averages_by_grade_by_month: course_progress_averages,
    } = data

    if (isEmpty(course_progress_averages)) {
      return null
    }

    return {
      course_progress_averages: A(
        course_progress_averages
          .filter((grade) => grade.grade_position !== null)
          .map((grade) => ({
            ...grade,
            months: fillMonths(grade.months, period, {
              avg_max_lesson: null,
            }),
          })),
      ).sortBy('grade_position'),
    }
  })
}
