import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'

/**
 * Fetches the driving_test_details_totals_by_month endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 * The shape of this data depends on whether the scope is district, as do the empty checks
 */
export default function drivingTestDetailsTotalsByMonth(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)
  const url = this.buildUrl(
    'driving_test_details_totals_by_month',
    product,
    studentScope,
  )
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    if (isEmpty(data)) {
      return null
    }

    return {
      ...data,
      months: fillMonths([], period),
    }
  })
}
