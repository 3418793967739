import { classNames } from '@ember-decorators/component'
import { service } from '@ember/service'
import { equal } from '@ember/object/computed'
import Component from '@ember/component'
import { computed } from '@ember/object'
import { A } from '@ember/array'
import { getColour } from 'district-ui-client/utils/giraffe/colour-picker'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'

/**
 * Turns a student-events stories_written_by_grade response into a giraffe chart
 * using the dashboard-title and series-bar primitives.
 *
 * Data format:
 *
 * ```
 * {
 *   stories_written_by_grade: [
 *     {
 *       grade_position: 0,
 *       story_count: 8,
 *       student_count: 5
 *     },
 *     {
 *       grade_position: 1,
 *       story_count: 15,
 *       student_count: 5
 *     },
 *     ...
 *   ]
 * }
 * ```
 * @class DashboardStoriesWrittenComponent
 * @extends Ember.Component
 * @property {Object} data - The data used to render the chart
 * @property {Object} data.stories_written_by_grade - Number of stories written and student count for each grade
 * @property {Number} data.stories_written_by_grade[].grade_position - The position number for the grade
 * @property {Number} data.stories_written_by_grade[].student_count - The number of students
 * @property {String} product - The product the chart is being used in.
 * @property {String} [title] - The chart title
 */
@classNames('h-full', 'giraffe-dashboard-tile', 'giraffe-dashboard-stories-written')
export default class StoriesWritten extends Component {
  @service gradeSets

  @service intl

  @computed('product')
  get colour() {
    const { product } = this
    const key = `${product}.storiesWritten.byGrade`
    return getColour(key)
  }

  /**
   * The series labels for the chart, formatted for Highcharts.
   * A list of formatted grade names.
   *
   * @instance
   * @memberOf DashboardStoriesWrittenComponent
   */
  @computed('data.stories_written_by_grade')
  get categories() {
    const chartData = this.data?.stories_written_by_grade
    return A(chartData.map((grade) => this.gradeSets.findByPosition(grade.grade_position)?.name ?? '-'))
  }

  /**
   * Bar data formatted for Highcharts
   *
   * @instance
   * @memberOf DashboardStoriesWrittenComponent
   */
  @computed('colour', 'data.stories_written_by_grade')
  get bars() {
    const color = this.colour
    const byGrade = this.data?.stories_written_by_grade
    return [
      {
        data: byGrade.map((grade) => {
          if (grade.student_count === 0) {
            return 0
          }
          return Math.round(grade.story_count / grade.student_count)
        }),
        color,
      },
    ]
  }

  @computed('singleGrade')
  get subtitleKey() {
    const { singleGrade } = this
    if (singleGrade) return 'reporting.dashboardSubtitles.singleGrade.storiesWritten'
    return 'reporting.dashboardSubtitles.multiGrade'
  }

  /**
   * Flag if data has only one grade
   *
   * @instance
   * @memberOf DashboardStoriesWrittenComponent
   */
  @equal('data.stories_written_by_grade.length', 1) singleGrade

  @computed
  get xAxisLabel() {
    return this.intl.t('grade.label')
  }

  @computed('singleGrade')
  get yAxisLabel() {
    return this.singleGrade ? 'Number of stories' : 'Stories Written'
  }

  /**
   * Total number of students across all grades
   * Calculates number of contributing students
   *
   * @instance
   * @memberOf DashboardStoriesWrittenComponent
   */
  @computed('data.student_count')
  get studentCount() {
    const count = this.data?.student_count
    return formatNumber(count, 2)
  }

  singleGradeChartMargin = [55, null, 104, null]
}
