import { isEmpty } from '@ember/utils'

/**
 * Fetches the mental_minute_overview endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default async function mentalMinuteOverview(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return null

  const url = this.buildUrl('mental_minute_overview', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  const data = await this.fetchData(url, query)
  const {
    mental_minute_overview: { summary, aggregate, students },
  } = data

  const isDataEmpty =
    isEmpty(summary) || isEmpty(aggregate) || isEmpty(students)
  return isDataEmpty ? null : { summary, aggregate, students }
}
