import { template as template1 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isHTMLSafe, htmlSafe, type SafeString } from '@ember/template';
import { isEmpty, isNone } from '@ember/utils';
import { keyResponder, onKey } from 'ember-keyboard';
import templateCompiler from 'lodash/template';
import cloneDeep from 'lodash/cloneDeep';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import FormSelectOption, { type OptionValue, type SelectOption, type ValueKey } from 'district-ui-client/components/form-components/form-select/option';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import { tracked } from '@glimmer/tracking';
import autoFocus from 'district-ui-client/modifiers/auto-focus';
const { isArray } = Array;
interface Args {
    closeAction?: () => void;
    defaultText?: string;
    disabled?: boolean;
    labelKey?: string;
    openAction?: () => void;
    optionClick?: (value: any) => void;
    options: SelectOption[];
    optionTemplate?: SafeString | string;
    optionTemplateSubLevel?: SafeString | string;
    resetAction?: () => void;
    search?: boolean;
    searchPlaceholder?: string;
    secondLevelOnly?: boolean;
    selectedTemplate?: string;
    selectedTemplateSubLevel?: string;
    staticAfterOptions?: SelectOption[];
    staticBeforeOptions?: SelectOption[];
    value?: SelectOption | string | number;
    valueLookupKey?: ValueKey;
}
interface Signature {
    Element: HTMLDivElement;
    Args: Args;
}
function flattenArray(acc1: SelectOption[], option1: SelectOption) {
    if (isArray(option1.value)) {
        acc1.push(option1) // add the parent option
        ;
        option1.value.forEach((child1)=>{
            child1.parentId = option1.uid;
        });
        return option1.value.reduce<SelectOption[]>(flattenArray, acc1);
    }
    acc1.push(option1);
    return acc1;
}
/**
 * Renders a template with lodash. It uses standard js templates so a template could looks like
 *
 * 'hello ${planet}'
 *
 */ export function renderTemplateToString(optionTemplateString1: string | SafeString, optionData1: SelectOption) {
    const wasSafe1 = isHTMLSafe(optionTemplateString1);
    const template1 = templateCompiler(wasSafe1 ? optionTemplateString1.toString() : optionTemplateString1);
    try {
        const compiled1 = template1(optionData1);
        return wasSafe1 ? htmlSafe(compiled1) : compiled1;
    } catch  {
        // fallback to default text
        return optionData1.text;
    }
}
/**
 * The formSelect component creates a form controls which implements search, option groups, keyboard
 * controls, custom look and feel.
 *
 * @class FormSelectComponent
 *
 * @property {String}  defaultText              - shows up when no option is selected or value set.
 * @property {Object}  value                    - this holds the currently selected option
 * @property {Array}   options                  - Is a array of options which populate the dropdown.
 * @property {Array}   staticBeforeOptions      - Is a array of options which will be shown before
 *                                                the searchable options
 * @property {Array}   staticAfterOptions       - Is a array of options which will be shown after
 *                                                the searchable options
 * @property {Object}  keySelectedOption        - Like value, but holds a temporary option which is
 *                                                selected via the keyboard
 * @property {Boolean} secondLevelOnly          - If set to true, only the second level of the option list
 *                                                is selectable and searchable.
 * @property {String}  valueLookupKey           - If unset, when an item is selected the entire item
 *                                                will be set as the value of the form-select control.
 *                                                On the other hand if this is set, when an item is selected,
 *                                                the form-select control will use this property name to
 *                                                look up its value within the selected item instead.
 * @property {Boolean} search                   - Enables or disables the search functionality
 * @property {String}  optionTemplate           - A string which holds a JS template to render an option
 * @property {String}  optionTemplateSubLevel   - A string which holds a JS template to render an option
 *                                                which is a child of an option (child of a group)
 *
 * @property {String}  selectedTemplate         - A string which holds a JS template to render the
 *                                                text which gets displayed after selection
 * @property {String}  selectedTemplateSubLevel - A string which holds a JS template to render the
 *                                                text which gets displayed after selection of a 2nd
 *                                                level option
 *
 * @property {Boolean} disabled                 - A boolean that disables the form-select/button when set to `true`
 * @property {Function} filterFunction          - Override point for filter function that is used to filter options by
 *                                                See default function below for argument requirements
 *
 * @example
 *
 *  {{reporting/form-select
 *    defaultText='Select school'
 *    staticBeforeOptions=beforeOptions
 *    options=schoolOptions
 *    search=true
 *    searchPlaceholder='Search Teachers'
 *    optionClick=changeScope
 *    value=selectedOption
 *    valueLookupKey='value'
 *    optionTemplate='<i class="fa fa-user"></i> ${text} - ${uid}'
 *    optionTemplateSubLevel='${text} - ${uid}'
 *    selectedTemplate='${text}'
 *    selectedTemplateSubLevel='Sub - ${text}'
 *  }}
 *
 *  Option structure, a plain object which need the following props, but can have more.
 *  Please note that you can create recursive structures. The same structure applies to the staticBefore|After options
 *
 *  [
 *    {
 *      text: 'This is a text',
 *      id: 1,
 *      uid: 1,
 *      value: 10
 *    },
 *    {
 *      text: 'This is a text',
 *      id: 2,
 *      uid: 2,
 *      value: [{ text: 'ha', ... }]
 *    },
 *  ]
 */ @keyResponder
export class FormComponentsFormSelect extends Component<Signature> {
    @service
    activeRoute: ActiveRouteService;
    get componentId() {
        return guidFor(this);
    }
    get componentElement() {
        return document.querySelector(`[component-id="${this.componentId}"]`);
    }
    @tracked
    keyboardActivated = false;
    @tracked
    keySelectedOption?: SelectOption | null;
    @tracked
    isSelected?: boolean;
    @tracked
    searchTerm = '';
    get defaultText() {
        return this.args.defaultText ?? 'Please override';
    }
    get staticBeforeOptions() {
        return this.args.staticBeforeOptions ?? [];
    }
    get staticAfterOptions() {
        return this.args.staticAfterOptions ?? [];
    }
    scrollOffset = 48;
    get optionTemplate() {
        return this.args.optionTemplate ?? '${text}';
    }
    get optionTemplateSubLevel() {
        return this.args.optionTemplateSubLevel ?? '${text}';
    }
    get selectedTemplate() {
        return this.args.selectedTemplate ?? '${text}';
    }
    get selectedTemplateSubLevel() {
        return this.args.selectedTemplateSubLevel ?? '${text}';
    }
    openAction() {
        this.args.openAction?.();
    }
    closeAction() {
        this.args.closeAction?.();
    }
    optionClick(value1?: OptionValue) {
        this.args.optionClick?.(value1);
    }
    get activeOption() {
        return this.args.value;
    }
    get offset() {
        return this.args.search ? this.scrollOffset : 0;
    }
    get options() {
        return this.args.options ?? [];
    }
    /**
   * Event handler to close the dropdown which blurs the button and
   * triggers the focusOut event on the component (due to the event bubbling up)
   */ @onKey('Escape', {
        event: 'keydown'
    })
    onEscKeyDown() {
        this.closeDropdown();
    }
    /**
   * Keyboard event handler for the ArrowDown key which gets fired when we press down.
   * We need to prevent the default behavior of this button when the dropdown is active. If we
   * would not do this we would scroll the page natively
   */ @onKey('ArrowDown', {
        event: 'keydown'
    })
    onArrowDownKeyDown(event1: KeyboardEvent) {
        event1.preventDefault();
    }
    /**
   * Keyboard event handler for the ArrowUp key which gets fired when we press down.
   * We need to prevent the default behavior of this button when the dropdown is active. If we
   * would not do this we would scroll the page natively
   */ @onKey('ArrowUp', {
        event: 'keydown'
    })
    onArrowUpKeyDown(event1: KeyboardEvent) {
        event1.preventDefault();
    }
    /**
   * Keyboard event handler for the Tab key which gets fired when we press down.
   * We need to prevent the default behavior of this button when the dropdown is active.
   */ @onKey('Tab', {
        event: 'keydown'
    })
    onTabKeyDown(event1: KeyboardEvent) {
        event1.preventDefault();
        if (this.isSelected) this.closeDropdown();
    }
    /**
   * Keyboard event handler for the arrowDown key which selects the next option and
   * scrolls to it smoothly. Please notice this happens on KEY UP
   */ @onKey('Tab', {
        event: 'keyup'
    })
    onTabKeyUp(event1: KeyboardEvent) {
        event1.preventDefault();
    }
    /**
   * Keyboard event handler for the arrowDown key which selects the next option and
   * scrolls to it smoothly. Please notice this happens on KEY UP
   */ @onKey('ArrowDown', {
        event: 'keyup'
    })
    onArrowDownKeyUp() {
        this.nextOption();
        this.scrollToOption();
    }
    /**
   * Keyboard event handler for the arrowUp key which selects the previous option and
   * scrolls to it smoothly. Please notice this happens on KEY UP
   */ @onKey('ArrowUp', {
        event: 'keyup'
    })
    onArrowUpKey() {
        this.previousOption();
        this.scrollToOption();
    }
    /**
   * Keyboard event handler for the enter key which sets the currently
   * selected option via the list-click action.
   */ @onKey('Enter', {
        event: 'keyup'
    })
    onEnterKey() {
        /*
     * Use the option selected by the keyboard. If falsey (enter hit when nothing selected, eg no search results), then
     * use the currently active option instead. It is possible for both of these to be falsey, in which case do nothing.
     */ const activeOption1 = this.keySelectedOption || this.activeOption;
        if (!isNone(activeOption1)) this.listClick({
            option: activeOption1
        });
    }
    /**
   * Creates a one level array of all the search options
   */ get flatOptions() {
        const options1 = this.searchOptions;
        return options1.reduce<SelectOption[]>(flattenArray, []);
    }
    get flatOptionsFilteredForSecondLevelOnly() {
        const { flatOptions: flatOptions1 } = this;
        const { secondLevelOnly: secondLevelOnly1 } = this.args;
        // only return child options
        if (secondLevelOnly1) {
            return flatOptions1.filter((option1)=>option1.parentId !== undefined);
        }
        return flatOptions1;
    }
    /**
   * The text gets displayed when we have selected an option or set the a value
   * If we dont have a value set we will fallback to a default text
   * This will use a template to render the selected option.
   *
   */ get text() {
        const activeOption1 = this.getActiveOption();
        if (!activeOption1) return this.defaultText;
        const { selectedTemplate: selectedTemplate1 } = this;
        const { selectedTemplateSubLevel: selectedTemplateSubLevel1 } = this;
        let text1;
        if (activeOption1.parentId) {
            text1 = renderTemplateToString(selectedTemplateSubLevel1, activeOption1);
        } else {
            text1 = renderTemplateToString(selectedTemplate1, activeOption1);
        }
        return text1;
    }
    get showResetButton() {
        return this.activeOption && this.args.resetAction && this.isSelected;
    }
    /**
   * This property is used to display the options of the dropdown.
   * If search is enabled we will apply a fuzzy search addon which has a little bit better
   * search results for instance for wrongly typed names and such.
   *
   */ get searchOptions() {
        const beforeOptions1 = this.staticBeforeOptions.map((opt1)=>({
                ...opt1,
                isStatic: true
            }));
        const afterOptions1 = this.staticAfterOptions.map((opt1)=>({
                ...opt1,
                isStatic: true
            }));
        const options1 = this.options.filter(Boolean);
        if (isEmpty(options1)) return [
            ...beforeOptions1,
            ...afterOptions1
        ];
        if (isEmpty(this.searchTerm)) return [
            ...beforeOptions1,
            ...options1,
            ...afterOptions1
        ];
        const filteredOptions1 = this.filterWithFilterFunction(options1, this.searchTerm);
        return [
            ...beforeOptions1,
            ...filteredOptions1,
            ...afterOptions1
        ];
    }
    /**
   * The default filter function is an adapted implementation of one found in our data-tables addon. Re-implementing it
   * here to avoid requiring it as a dependency.
   * This function is overrideable.
   *
   */ filterFunction(items1: SelectOption[], searchTerm1: string) {
        // For a given data item, this function returns true if every keyword is present in the data
        const textFilterMatches1 = (dataString1: string, searchText1: string)=>{
            const filterKeywords1 = searchText1.toLowerCase().split(' ').filter((str1)=>str1.length > 0);
            return filterKeywords1.every((keyword1)=>dataString1.match(keyword1));
        };
        const filterPredicate1 = (dataItem1: SelectOption)=>{
            const dataString1 = dataItem1.text?.toLowerCase() ?? '';
            const itemMatches1 = textFilterMatches1(dataString1, searchTerm1);
            return itemMatches1;
        };
        // When filtering data items by the text
        return items1.filter(filterPredicate1);
    }
    /**
   *
   * There are 2 options:
   * 1. Search on the first level only, which only reduces the first level
   * 2. Search on the second level which only reduces secondary options
   *
   */ filterWithFilterFunction(options1: SelectOption[], searchTerm1: string) {
        const { secondLevelOnly: secondLevelOnly1 } = this.args;
        if (secondLevelOnly1) {
            // we need to clone the object as we are modifying the value prop. This also makes
            // sure we are not calling render twice.
            return cloneDeep(options1).reduce<SelectOption[]>((accumulator1, option1)=>{
                const firstLevelOptions1 = option1.value;
                // dont search in first level elements
                if (!isArray(firstLevelOptions1)) return accumulator1;
                const result1 = this.filterFunction(firstLevelOptions1, searchTerm1);
                // set search result to the current first level option
                // and dont add it when we dont have any results at all.
                option1.value = result1;
                if (result1.length) accumulator1.push(option1);
                return accumulator1;
            }, []);
        }
        return this.filterFunction(options1, searchTerm1);
    }
    /**
   * Selects and sets the next option in the dropdown based on the flat representation of the options.
   * This is important for the keyboard controls.
   */ nextOption() {
        const activeOption1 = this.getActiveOption();
        const flatOptions1 = this.flatOptionsFilteredForSecondLevelOnly;
        const flatOptionsCount1 = flatOptions1.length;
        const currentIndex1 = activeOption1 ? flatOptions1.indexOf(activeOption1) : -1;
        let nextIndex1 = currentIndex1 + 1;
        if (nextIndex1 === flatOptionsCount1) nextIndex1 = flatOptionsCount1 - 1;
        this.keySelectedOption = flatOptions1[nextIndex1];
    }
    /**
   * Selects and sets the previous option in the dropdown based on the flat representation of the options.
   * This is important for the keyboard controls.
   */ previousOption() {
        const activeOption1 = this.getActiveOption();
        const flatOptions1 = this.flatOptionsFilteredForSecondLevelOnly;
        const currentIndex1 = flatOptions1.indexOf(activeOption1 as SelectOption);
        let prevIndex1 = currentIndex1 - 1;
        if (prevIndex1 <= 0) prevIndex1 = 0;
        this.keySelectedOption = flatOptions1[prevIndex1];
    }
    /**
   * This returns a dom element by uid
   */ getSelectedDomElement(): HTMLElement | null | undefined {
        const activeOption1 = this.getActiveOption();
        const optionValue1 = this.getTheOptionValue(activeOption1 as SelectOption);
        const isStringOrNumber1 = typeof optionValue1 == 'string' || typeof optionValue1 == 'number';
        if (isStringOrNumber1) return this.componentElement?.querySelector(`[data-id="${optionValue1}"]`);
    }
    findOptionByUid(uid1: string | number) {
        const flatOptions1 = this.flatOptions;
        return flatOptions1.find((option1)=>this.getTheOptionValue(option1) === uid1) || null;
    }
    /**
   * ScrollToOptions jumps to the selected element
   */ scrollToOption() {
        const selectedElement1 = this.getSelectedDomElement();
        if (!selectedElement1) return;
        const topPos1 = selectedElement1.offsetTop;
        const scroller1 = this.componentElement?.querySelector('.scroller > ul');
        if (scroller1) {
            scroller1.scrollTop = -this.offset + topPos1;
        }
    }
    /**
   * When the component becomes focused through a button which bubbles up to the component
   * then we open the dropdown, activate keyboard inputs, and scroll to the currently active component.
   */ @action
    onTriggerClick(event1: MouseEvent) {
        if (this.args.disabled) return;
        const isDropDownOpen1 = this.isSelected;
        if (isDropDownOpen1 === true) {
            return true;
        }
        event1.preventDefault();
        event1.stopPropagation();
        this.openDropdown();
    }
    openDropdown() {
        this.isSelected = true;
        this.keyboardActivated = true;
        this.openAction();
        this.scrollToOption();
    }
    @action
    closeDropdown() {
        this.keySelectedOption = null;
        this.keyboardActivated = false;
        this.closeAction();
        this.isSelected = false;
    }
    /**
   * Helper method which always returns the current active option no matter what input type
   * and selected value is given.
   *
   */ private getActiveOption() {
        const activeOption1 = this.keySelectedOption || this.activeOption;
        if (typeof activeOption1 === 'string' || typeof activeOption1 === 'number') {
            return this.findOptionByUid(activeOption1);
        }
        // fall back onto value if uid is not set, some dropdown do not use uid
        const id1 = this.getTheOptionValue(activeOption1);
        if (typeof id1 === 'string' || typeof id1 === 'number') {
            return this.findOptionByUid(id1);
        }
        return null;
    }
    /**
   * Returns the actual return value where we check if we have valueLookupKey first and fallback to
   * `uid` or `value` for compatibility reasons. To make absolutely sure we are retrieving the correct
   * value we have to check if the a value is not undefined and return right away.
   */ private getTheOptionValue(option1?: SelectOption) {
        if (!option1) return null;
        // use fake xyz prop to force an undefined value (safety option)
        const valueLookupKey1 = this.args.valueLookupKey || 'xyz';
        if (option1[valueLookupKey1] !== undefined) return option1[valueLookupKey1];
        if (option1.uid !== undefined) return option1.uid;
        if (option1.value !== undefined) return option1.value;
        throw new Error('Cant retrieve a value without a defined valueLookupKey or uid or value');
    }
    /**
   * Resetting the dropdown will unselect the selected value, close the dropdown and
   * call the resetAction so the consuming app can respond to that change.
   */ @action
    resetSelection(e1: Event) {
        e1.preventDefault();
        e1.stopPropagation();
        this.args.resetAction?.();
        this.closeDropdown();
        return false;
    }
    @action
    listClick(optionComponent1: {
        option: SelectOption | string | number;
    }) {
        const data1 = optionComponent1.option;
        let returnValue1;
        if (typeof data1 === 'string' || typeof data1 === 'number') {
            returnValue1 = data1;
        } else {
            returnValue1 = this.args.valueLookupKey ? data1[this.args.valueLookupKey] : data1;
        }
        this.optionClick(returnValue1);
        this.closeDropdown();
        return false;
    }
    @action
    resetSearch() {
        this.searchTerm = '';
        const element1 = this.componentElement?.querySelector('input[type="text"]');
        if (element1) {
            ;
            (element1 as HTMLInputElement).focus();
        }
    }
    @action
    onSearchInput(event1: Event) {
        this.searchTerm = (event1.target as HTMLInputElement)?.value;
    }
    static{
        template1(`
    <div
      component-id={{this.componentId}}
      class="form-components-form-select dropdown form-select {{if @search 'has-search'}}"
      ...attributes
    >
      {{! Styled similarly to a muted UiButton with themed outline - text colour is lighter though }}
      {{! Ideally we could just use UiButton here, but the behaviour needed is different - we want the outline applied while the dropdown is open, rather than just on focus }}
      <button
        data-test-form-select-button
        type="button"
        class="form-components-form-select-button border-dusty-black-100 text-dusty-black-300 hover:bg-dusty-black-50 hover:text-dusty-black-300 focus:bg-dusty-black-50 focus:text-dusty-black-300 disabled:border-dusty-black-100 disabled:bg-dusty-black-50 disabled:text-dusty-black-200 inline-flex select-none items-center gap-2 rounded-md border bg-white p-2 text-left outline outline-2 outline-transparent transition-all duration-200 disabled:pointer-events-none disabled:cursor-default
          {{if this.isSelected 'scroller-opened'}}
          {{if
            (eq this.activeRoute.subscriptionType 'reading')
            'focus:outline-oceany-blue-300/[0.75] [&.scroller-opened]:outline-oceany-blue-300/[0.75]'
          }}
          {{if
            (eq this.activeRoute.subscriptionType 'maths')
            'focus:outline-ms-green-300/[0.75] [&.scroller-opened]:outline-ms-green-300/[0.75]'
          }}
          {{if
            (eq this.activeRoute.subscriptionType 'writing')
            'focus:outline-wl-blue-300/[0.75] [&.scroller-opened]:outline-wl-blue-300/[0.75]'
          }}"
        disabled={{@disabled}}
        {{on "click" this.onTriggerClick}}
      >
        <span>{{this.text}}</span>
        {{#if this.showResetButton}}
          <span
            data-test-form-select-reset
            aria-label={{t "components.formComponents.resetSelectionAria"}}
            role="button"
            {{on "click" this.resetSelection}}
          >
            <FaIcon @icon="circle-xmark" class="text-dusty-black-300" />
          </span>
        {{/if}}
        {{! This empty span is here to produce a larger gap to the arrow, than between the reset icon and text. }}
        <span></span>
        <FaIcon @icon="caret-down" @size="lg" @transform="shrink-2" class="text-dusty-black-300 ml-auto" />
      </button>
      {{#if this.isSelected}}
        <div class="scroller {{if this.isSelected 'block' 'hidden'}}">
          <ul data-test-form-select-dropdown>
            {{#if @search}}
              <li class="search" data-test-form-select-search>
                <input
                  data-test-form-select-search-input
                  type="text"
                  name="select-search"
                  value={{this.searchTerm}}
                  aria-label={{t "components.formComponents.searchAria"}}
                  placeholder={{@searchPlaceholder}}
                  autocomplete="off"
                  {{on "input" this.onSearchInput}}
                  {{autoFocus}}
                />
                {{#if this.searchTerm}}
                  <span
                    role="button"
                    aria-label={{t "components.formComponents.resetSelectionAria"}}
                    {{on "click" this.resetSearch}}
                  >
                    <FaIcon @icon="circle-xmark" />
                  </span>
                {{else}}
                  <FaIcon @icon="search" />
                {{/if}}
              </li>
            {{/if}}
            {{#each this.searchOptions as |option index|}}
              <FormSelectOption
                @option={{option}}
                @listClickAction={{this.listClick}}
                @value={{@value}}
                @keySelectedOptionValue={{this.keySelectedOption}}
                @parentId={{this.componentId}}
                @optionIndex="{{index}}"
                @optionTemplate={{this.optionTemplate}}
                @optionTemplateSubLevel={{this.optionTemplateSubLevel}}
                @valueLookupKey={{@valueLookupKey}}
                @secondLevelOnly={{@secondLevelOnly}}
              />
            {{else}}
              <li class="default" data-test-form-select-no-results>{{t "components.formComponents.noResults"}}</li>
            {{/each}}
          </ul>
        </div>
        <button type="button" class="dropdown-overlay" {{on "click" this.closeDropdown}}></button>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default FormComponentsFormSelect;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'FormComponents::FormSelect': typeof FormComponentsFormSelect;
    }
}
