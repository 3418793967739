import { A } from '@ember/array'
import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the stadium_events endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function stadiumEvents(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('stadium_events', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const isValid =
      data.hasOwnProperty('stadium_events') &&
      data.stadium_events.length === 4 &&
      A(data.stadium_events).every(
        (stadiumEvent) =>
          stadiumEvent.hasOwnProperty('event_type') &&
          stadiumEvent.hasOwnProperty('count'),
      ) &&
      // valid if at least one of the counts is not zero,
      // i.e. non-valid when all of them are zero
      !A(data.stadium_events).every((stadiumEvent) => stadiumEvent.count === 0)

    if (!isValid) {
      return null
    }

    return data
  })
}
