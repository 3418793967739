import Component from '@glimmer/component'
import { service } from '@ember/service'
import { isEmpty } from '@ember/utils'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import lexileYRange from 'district-ui-client/utils/giraffe/lexile-y-range'
import {
  gradePositionRangeFromLexiles,
  lexileRangeFromGradePosition,
} from 'district-ui-client/utils/giraffe/lexile-grade-map'
import { reportingPlotLine, reportingLegend } from 'district-ui-client/utils/giraffe/highcharts-config'
import colors from '@blakeelearning/blake-colours/colours'

const colorGradeMap = {
  0: colors.watermelonyRed300,
  1: colors.grapeyGreen400,
  2: colors.oceanyBlue300,
  3: colors.juicyOrange300,
  4: colors.purplyPink300,
  5: colors.watermelonyRed300,
  6: colors.oceanyBlue400,
  7: colors.yolkyYellow300,
  8: colors.oceanyBlue500,
  9: colors.grapeyGreen300,
}

/**
 * Puts together all the pieces for the lexile details averages chart.
 *
 * @class LexileDetailsAveragesComponent
 * @extends Ember.Component
 * @property {String} [title] - The chart title.
 * @property {String} [summaryTitle] - The summary box title.
 * @property {String} [grade] - The grade the chart represents.
 * @property {Object} data - The data used to render the chart
 * @property {Number} data.grade_position - The grade position
 * @property {Object[]} data.summary - The summary of the months data
 * @property {Object} data.summary[].category - The category of the summary data
 * @property {Object} data.summary[].count - The value of the summary data
 * @property {Object[]} data.months - The data for each month
 * @property {Object} data.months[].lexile_average - The lexile average for this month
 * @property {Object} data.months[].month - The month this data represents
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'lexile_gain', count: 300 },
 *     { category: 'class_average', count: 500 },
 *     { category: 'student_count', count: 10 },
 *   ],
 *   months: [
 *     {
 *       lexile_average: 70,
 *       month: '2016-07',
 *     },
 *     {
 *       lexile_average: 120,
 *       month: '2016-08',
 *     },
 *   ],
 * }
 * ```
 */
export default class ChartsLexileDetailsAverages extends Component {
  @service gradeSets

  @service intl

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display lexile growth and average lexile level for the class.
   */
  get summaryStats() {
    const summaryData = this.args.data.summary ?? []
    const gain = summaryData.find((d) => d.category === 'lexile_gain').count
    const average = summaryData.find((d) => d.category === 'class_average').count

    return [
      { label: 'Class Average Lexile Growth', value: `+${gain}L` },
      { label: 'Class Average Lexile Level', value: `${average}L` },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get chartData() {
    const monthsData = this.args.data.months ?? []
    const gradePosition = this.args.data.grade_position
    // if grade position beyond colors in map, loop back around to first color
    const color = colorGradeMap[gradePosition % Object.keys(colorGradeMap).length]

    const data = monthsData.map((monthData, index) => ({ x: index, y: monthData.lexile_average, monthData }))
    return [{ name: 'lexile_average', color, data }]
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data.months?.map((monthData) => convertDateFormat(monthData.month)) ?? []
  }

  get legend() {
    return reportingLegend('left', 'top', 50, 10)
  }

  /**
   * Plot lines to mark the grade bands for the lexile values.
   */
  get plotLines() {
    const { product } = this.args
    const monthsData = this.args.data.months ?? []
    const lexiles = monthsData.map((m) => m.lexile_average).filter((lexileAvg) => !isEmpty(lexileAvg))
    if (isEmpty(lexiles)) return []

    const positionPadding = 1 // add extra plotline to chart
    const gradePositionRange = gradePositionRangeFromLexiles(product, lexiles, positionPadding)

    return gradePositionRange
      .map((gradePosition) => {
        const { min: lexile } = lexileRangeFromGradePosition(product, gradePosition)
        const label = this.gradeSets.findByPosition(gradePosition)?.fullName ?? '-'

        if (isEmpty(lexile)) {
          return null
        }

        return reportingPlotLine(lexile, label)
      })
      .filter((plotLine) => !isEmpty(plotLine))
  }

  /**
   * Set the yRange of the chart to include all lessons for all grades in the data
   */
  get yRange() {
    const { product } = this.args
    const monthsData = this.args.data.months ?? []
    const lexiles = monthsData.map((m) => m.lexile_average).filter((lexileAvg) => !isEmpty(lexileAvg))
    const [min, max] = lexileYRange(product, lexiles)
    return [min, max]
  }

  /**
   * Set the tooltip of the points in the chart to include the student count
   */
  get toolTipFormatter() {
    const studentCountLabel = this.intl.t('reporting.statBoxes.assessmentScoresTotals.studentCount')

    return function () {
      if (this.color !== 'transparent') {
        const { monthData } = this.point
        const studentCount = monthData.student_count

        return `<strong>${this.y}L<br>${studentCountLabel}: ${studentCount}</strong>`
      }
    }
  }
}
