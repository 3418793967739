import { tagName } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'
import Component from '@ember/component'
import { reportingTitle, reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'
import BlakeColours from '@blakeelearning/blake-colours/colours'

/**
 * An ember component interface to a highcharts bar chart.
 *
 * Data is in this format:
 *
 * ```
 * [
 *   { name: 'series-0-name', data: [1, 2, 3] },
 *   { name: 'series-1-name', data: [3, 4, 5] },
 * ]
 * ```
 *
 * Use 1 series for a bar chart, multiple series for a stacked or grouped
 * bar chart.
 *
 * @class PrimitiveSeriesBar
 *
 * @property {Boolean} [animation=true] - Disable or enable animation effects. Useful for testing.
 * @property {Array} categories - The categories for x axis labels
 * @property {Array} [chartMargin=[null]] - Provide your own chart margin values. The order is Top,
 *                                     Right, Bottom, Left. A `null` value will let highcharts
 *                                     calculate that value.
 * @property {Number} [barPadding=0.1] - Number between 0 and 1 controlling how much padding a bar
 *                                       has in the space available to it.
 * @property {Number} [chartSpacing=10] - Provide your own chart spacing value to add space around the chart.
 * @property {Boolean} [colorByPoint=false] - if true, colours apply per 'point' (bar), rather than per series.
 * @property {Array} colours - The colours for the different series
 * @property {HighChartsSeries} data - The series data
 * @property {HighChartsLegend} [legend=disabled] - Highcharts legend config
 * @property {Number} [groupPadding=0.2] - Number between 0 and 1 controlling space between groups.
 * @property {HighChartsPlotBands} [plotBands=[]] - Array of Highcharts plotBands config objects.
 *                                                  Defaults to an empty array (no plot bands)
 * @property {HighChartsPlotLines} [plotLines=[]] - Array of Highcharts plotLines config objects.
 *                                                  Defaults to an empty array (no plot lines)
 * @property {HighChartsPlotOptions} [plotOptions] = (deprecated) Override the default plotOptions computed
 *                                                   property with your own. Setting this will
 *                                                   override `stackingStyle`, `animation`,
 *                                                   `groupPadding` and `barPadding`.
 * @property {String} [stackingStyle=null] - Choose a stacking style for multiple series. 'stacked' to make it stack.
 * @property {String|HighChartsTitle} [title=''] - Chart title if you want to provide one.
 * @property {String} [yAxisVisible=true] - Control the visibility of the y axis
 * @property {HighChartsToolTip} [toolTip] - (deprecated) Override the default tooltip computed property with
 *                                           your own. Setting this will override any other tooltip
 *                                           related properties. Set to 'false' to disable entirely.
 * @property {Function} [toolTipFormatter=null] - Formatter function used by Highcharts to format
 *                                                 the display of the toolTip values.
 * @property {String} [toolTipUnit=''] - Unit to display after the toolTip value.
 * @property {HighChartsXAxis} [xAxis] - (deprecated) Override the default xAxis computed property with your own.
 *                                       Setting this will override any other x axis related
 *                                       properties.
 * @property {String} [xAxisLabel=''] - X axis label, used for a standard x axis title
 * @property {Object} [xAxisTitle=null] - X axis title object, for finer control than xAxisLabel
 * @property {String} [xAxisVisible=true] - Control the visibility of the x axis
 * @property {HighChartsYAxis} [yAxis] - (deprecated) Override the default yAxis property with your own.
 *                                       Setting this will override any other y axis related
 *                                       properties.
 * @property {String} [yAxisLabel=''] - Y axis label
 * @property {Function} [yAxisLabelFormatter=null] - Formatter function used by Highcharts to
 *                                                    format the display of the y axis values.
 * @property {Number} [yAxisOffset=0] - The distance in pixels from the plot area to the axis line
 * @property {Boolean} [yAxisOpposite=false] - Whether to display the axis on the opposite side of the normal
 * @property {Number} [yAxisTickInterval=undefined] - The interval of the tick marks in axis units
 * @property {Boolean} [yAxisReversedStacks=true] - Changes the order of the stacked data in the chart
 * @property {Array} [yRange] - E.g., `[0, 100]`, lets you control the y axis range of the chart
 *                              instead of letting highcharts calculate its own range.

 * @example
 *   {{primitives/series-bar
 *     data=multiSeriesData
 *     colours=multiSeriesColours
 *     categories=categories
 *     legend=legend
 *   }}
 */
@tagName('')
export default class SeriesBar extends Component {
  colours = undefined

  @alias('colours')
  colors

  plotBackgroundColour = BlakeColours.dustyBlack50

  @alias('plotBackgroundColour')
  plotBackgroundColor

  yAxisGridLineColour = BlakeColours.dustyBlack100

  @alias('yAxisGridLineColour')
  yAxisGridLineColor

  @computed(
    'chartTitle',
    '_plotOptions',
    '_xAxis',
    '_yAxis',
    'plotBackgroundColor',
    'colors',
    'chartMargin',
    'chartSpacing',
    'legend',
    '_toolTip',
  )
  get chartOptions() {
    const disabled = { enabled: false }

    const {
      chartTitle: title,
      _plotOptions: plotOptions,
      _xAxis: xAxis,
      _yAxis: yAxis,
      plotBackgroundColor,
      colors,
      _toolTip: toolTip,
    } = this

    const spacing = this.chartSpacing === undefined ? 10 : this.chartSpacing
    const margin = this.chartMargin === undefined ? [null] : this.chartMargin
    const legend = this.legend === undefined ? disabled : this.legend

    const tooltip = toolTip || disabled

    const options = {
      chart: {
        type: 'bar',
        plotBackgroundColor,
        backgroundColor: 'transparent',
        spacing,
        margin,
      },
      credits: disabled,
      title,
      yAxis,
      xAxis,
      colors,
      plotOptions,
      tooltip,
      legend,
    }

    return options
  }

  /**
   * the public, overridable xAxis property
   * @deprecated please pass in individual properties
   */
  xAxis = null

  /**
   * the private computed xAxis property
   */
  @computed('categories.[]', 'xAxis', 'tickLength', 'xAxisLabel', 'xAxisLabelFormatter', 'xAxisVisible')
  get _xAxis() {
    if (this.xAxis !== null) {
      return this.xAxis
    }
    const xAxisLabel = this.xAxisLabel === undefined ? '' : this.xAxisLabel
    const { xAxisLabelFormatter } = this
    const visible = this.xAxisVisible === undefined ? true : this.xAxisVisible
    const categories = this.categories === undefined ? null : this.categories
    const tickLength = this.tickLength === undefined ? 10 : this.tickLength
    const allowDecimals = false

    const title = typeof xAxisLabel === 'string' ? reportingTitle(xAxisLabel) : xAxisLabel

    const xAxis = {
      title,
      categories,
      visible,
      tickLength,
      allowDecimals,
      lineColor: BlakeColours.dustyBlack200,
    }

    if (xAxisLabelFormatter) {
      xAxis.labels = {
        formatter: xAxisLabelFormatter,
      }
    }

    return xAxis
  }

  /**
   * the public, overridable yAxis property
   * @deprecated please pass in individual properties
   */
  yAxis = null

  /**
   * the private computed yAxis property
   */
  @computed(
    'yAxis',
    'yAxisLabel',
    'yAxisVisible',
    'yRange.[]',
    'plotBands.[]',
    'plotLines.[]',
    'yAxisOffset',
    'yAxisOpposite',
    'yAxisReversedStacks',
    'yAxisLineWidth',
    'yAxisGridLineWidth',
    'yAxisGridLineColor',
    'yAxisLabelFormatter',
    'yAxisTickInterval',
  )
  get _yAxis() {
    if (this.yAxis !== null) {
      return this.yAxis
    }
    const yAxisLabel = this.yAxisLabel === undefined ? '' : this.yAxisLabel
    const visible = this.yAxisVisible === undefined ? true : this.yAxisVisible
    const [min, max] = this.yRange === undefined ? [] : this.yRange
    const plotLines = this.plotLines === undefined ? [] : this.plotLines
    const plotBands = this.plotBands === undefined ? [] : this.plotBands
    const offset = this.yAxisOffset === undefined ? 0 : this.yAxisOffset
    const opposite = this.yAxisOpposite === undefined ? false : this.yAxisOpposite
    const reversedStacks = this.yAxisReversedStacks === undefined ? true : this.yAxisReversedStacks
    const lineWidth = this.yAxisLineWidth === undefined ? 0 : this.yAxisLineWidth
    const gridLineWidth = this.yAxisGridLineWidth === undefined ? 1 : this.yAxisGridLineWidth
    const gridLineColor = this.yAxisGridLineColor
    const { yAxisLabelFormatter } = this
    const tickInterval = this.yAxisTickInterval
    const allowDecimals = false

    const yAxis = {
      title: reportingTitle(yAxisLabel),
      visible,
      min,
      max,
      plotLines,
      plotBands,
      allowDecimals,
      offset,
      opposite,
      reversedStacks,
      lineWidth,
      gridLineWidth,
      gridLineColor,
      tickInterval,
    }

    if (yAxisLabelFormatter) {
      yAxis.labels = {
        formatter: yAxisLabelFormatter,
      }
    }

    return yAxis
  }

  /**
   * the public, overridable plotOptions property
   */
  plotOptions = null

  /**
   * the private computed plotOptions property
   */
  @computed(
    'colorByPoint',
    'stackingStyle',
    'animation',
    'plotOptions',
    'groupPadding',
    'barPadding',
    'maxPointWidth',
    'borderWidth',
  )
  get _plotOptions() {
    if (this.plotOptions !== null) {
      return this.plotOptions
    }
    const { stackingStyle, animation } = this
    const groupPadding = this.groupPadding === undefined ? 0.2 : this.groupPadding
    const pointPadding = this.barPadding === undefined ? 0.1 : this.barPadding
    const colorByPoint = this.colorByPoint === undefined ? false : this.colorByPoint
    const maxPointWidth = this.maxPointWidth === undefined ? null : this.maxPointWidth
    const borderWidth = this.borderWidth === undefined ? 0 : this.borderWidth

    return {
      bar: {
        borderWidth,
        stacking: stackingStyle,
        animation,
        groupPadding,
        pointPadding,
        colorByPoint,
        maxPointWidth,
      },
    }
  }

  @computed('title')
  get chartTitle() {
    const title = this.title === undefined ? '' : this.title
    if (typeof title === 'string') {
      return reportingTitle(title)
    }
    return title
  }

  /**
   * the public, overridable toolTip property
   */
  toolTip = null

  /**
   * the private computed toolTip property
   */
  @computed('toolTip', 'toolTipUnit', 'toolTipFormatter')
  get _toolTip() {
    if (this.toolTip !== null) {
      return this.toolTip
    }
    const unit = this.toolTipUnit === undefined ? '' : this.toolTipUnit
    const formatter = this.toolTipFormatter

    return toolTipConfig('y', unit, { formatter })
  }

  @computed('chartOptions', 'data.[]')
  get highchartOptions() {
    return { ...this.chartOptions, series: this.data }
  }
}
