// extracted from 'jquery-param'
//
// Original source here: https://github.com/knowledgecode/jquery-param/blob/28ae13666244cef8f2a0e6f7f153ad8cccb4c5bd/jquery-param.js
// Original jquery version: https://github.com/jquery/jquery/blob/79b74e043a4ee737d44a95094ff1184e40bd5b16/src/serialize.js

export default function (a) {
  const s = []
  const add = function (k, v) {
    v = typeof v === 'function' ? v() : v
    v = v === null ? '' : v
    v = v === undefined ? '' : v
    s[s.length] = `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  }

  function buildParams(prefix, obj) {
    let i
    let len
    let key

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            `${prefix}[${typeof obj[i] === 'object' && obj[i] ? i : ''}]`,
            obj[i],
          )
        }
      } else if (String(obj) === '[object Object]') {
        for (key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            buildParams(`${prefix}[${key}]`, obj[key])
          }
        }
      } else {
        add(prefix, obj)
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value)
      }
    } else {
      for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          buildParams(key, obj[key])
        }
      }
    }
    return s
  }

  return buildParams('', a).join('&')
}
