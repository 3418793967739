import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import fillMonths from '../../utils/fill-months'

/**
 * Fetches the awards_earned_by_month_events endpoint from the student-events API
 *
 * @param {String} product - The product this request is for
 * @param {Object} studentScope - The studentScope this request is for
 * @param {String} studentScope.scope -  The scope type, e.g. students, district
 * @param {Array} studentScope.ids - A list of scope type ids to query
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or null if data is empty
 */
export default function awardsEarnedByMonthEvents(
  product,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl(
    'awards_earned_by_month_events',
    product,
    studentScope,
  )
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const summaryKey = 'awards_earned_by_month_events_summary'
    const monthlyKey = 'awards_earned_by_month_events'
    const { [summaryKey]: summary } = data
    const { [monthlyKey]: months } = data

    if (summary.length === 0 || months.length === 0) {
      return null
    }

    if (isEmpty(data)) {
      return null
    }

    return {
      awards_earned: {
        summary: summary.data,
        months: fillMonths(months, period, {
          gold: null,
          silver: null,
          bronze: null,
        }),
      },
    }
  })
}
