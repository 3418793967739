import { notEmpty } from '@ember/object/computed'
import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { serialize as serializeSort, deserialize as deserializeSort } from '@blakeelearning/data-tables/utils/sorting'

export default class TableController extends Controller {
  queryParams = ['sort']

  @tracked sort = 'schoolName' // default sort

  @notEmpty('model.mentalMinuteSchoolOverview')
  isDataPresent

  get sortingConfig() {
    return deserializeSort(this.sort)
  }

  set sortingConfig(newSortConfig) {
    this.sort = serializeSort(newSortConfig)
  }

  updateSorts = (sortingConfig) => {
    this.sortingConfig = sortingConfig
  }
}
