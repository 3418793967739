import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type SessionService from 'district-ui-client/services/session'
import type ReportingUiScopeRoute from 'district-ui-client/routes/reporting/ui-scope'
import type { NormalizedInterval } from 'date-fns'

export interface DashboardTableSummary {
  schools: {
    school_id: number
    lessons_completed: number
    total_time: number
    total_usage: number
    assessments: number
    driving_tests_completed: number
    awards_earned_bronze: number
    awards_earned_silver: number
    awards_earned_gold: number
    average_lessons_completed_per_student: Nullable<number>
    average_assessments_per_student: Nullable<number>
    average_driving_tests_completed_per_student: Nullable<number>
  }[]
  district: {
    lessons_completed: number
    total_time: number
    total_usage: number
    assessments: number
    driving_tests_completed: number
    awards_earned_bronze: number
    awards_earned_silver: number
    awards_earned_gold: number
    average_lessons_completed_per_student: Nullable<number>
    average_assessments_per_student: Nullable<number>
    average_driving_tests_completed_per_student: Nullable<number>
  }
}

interface StatsResponse {
  summary: Nullable<{
    summary: DashboardTableSummary
  }>
}

export default class ReDashboardTableRoute extends Route {
  @service studentEvents!: StudentEventsService

  @service session!: SessionService

  async model() {
    const { period, scopedData, uiScope } = this.modelFor('reporting.ui-scope') as ModelFor<ReportingUiScopeRoute>

    // The /summary endpoint is overused and returns different data for the school scope. We need to always call it
    // with district scope
    const statsScope = { scope: 'district', ids: [this.session.currentDistrict.id] }

    const product = 're'
    const stats = await this.makeStatsRequest(product, statsScope, period)

    // if the scope was school, remove unrelated data from the stats.
    if (uiScope.scope === 'school' && stats.summary?.summary.schools) {
      stats.summary.summary.schools = stats.summary.summary.schools.filter(
        (schoolData) => String(schoolData.school_id) === uiScope.id,
      )
    }

    return {
      product,
      period,
      scopedSchools: scopedData.schools,
      ...stats,
    }
  }

  makeStatsRequest(
    product: string,
    statsScope: { scope: string; ids: string[] },
    period: NormalizedInterval<Date>,
  ): Promise<StatsResponse> {
    return RSVP.hash({
      summary: this.studentEvents.summary(product, statsScope, period),
    })
  }
}
