import Controller from '@ember/controller'
import { service } from '@ember/service'
import { action } from '@ember/object'

export default class IndexController extends Controller {
  @service router

  @action
  goToCategory(category) {
    const path = {
      lessons: 'course-progress.totals',
      assessments: 'quizzes-completed',
      driving_tests: 'driving-tests',
      books: 'books-read',
      spelling: 'spelling-progress.totals',
    }[category]
    const { scope: scopeName, id: scopeId } = this.model.scope
    this.router.transitionTo(`reporting.ui-scope.re.${path}`, scopeName, scopeId)
  }
}
