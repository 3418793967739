import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { type SafeString } from '@ember/template';
import { Tile, PanelTitle } from 'district-ui-client/components/section';
import { array } from '@ember/helper';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import { NoDataMessage } from 'district-ui-client/components/no-data-message';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        tooltip?: string | SafeString;
        linkPath?: string;
        empty?: boolean;
        title?: string;
        models?: unknown[];
        totalUsage?: boolean;
    };
    Blocks: {
        default: [];
    };
}
export const DashboardChartWrapper: TOC<Signature> = template(`
  <div class="w-full {{if @empty 'print:hidden'}} [page-break-inside:avoid]" ...attributes>
    <Tile @route={{@linkPath}} @models={{if @models @models (array)}}>
      <div class="{{if @totalUsage '' 'flex h-[350px] flex-col'}}">
        <div class="mb-2 flex items-center justify-between gap-4">
          <PanelTitle>{{@title}}</PanelTitle>
          <TooltipInfo @text={{@tooltip}} />
        </div>

        <div class="{{if @totalUsage '' 'relative grow'}}">
          <div data-test-dashboard-chart-inner class="{{if @totalUsage '' 'absolute inset-0'}}">
            {{#if @empty}}
              <NoDataMessage class="m-4" @compact={{true}} />
            {{else}}
              {{yield}}
            {{/if}}
          </div>
        </div>
      </div>
    </Tile>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardChartWrapper;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::DashboardChartWrapper': typeof DashboardChartWrapper;
    }
}
