import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import config from 'district-ui-client/config/environment';
import highcharts from '@blakeelearning/highcharts/modifiers/highcharts';
import printReflow from 'district-ui-client/modifiers/print-reflow';
import chartReflow from 'district-ui-client/modifiers/chart-reflow';
// Provide access to highcharts-more charts, which we currently use for 'columnrange' for primitives/series-column-range
HighchartsMore(Highcharts);
interface Signature {
    Element: HTMLDivElement;
    Args: {
        options: Highcharts.Options;
        setChart?: (chart: Highcharts.Chart) => unknown;
    };
}
export default class BaseHighcharterComponent extends Component<Signature> {
    chart?: Highcharts.Chart;
    @action
    setChart(chart1: Highcharts.Chart) {
        this.chart = chart1;
        this.args.setChart?.(chart1);
    }
    get options(): Highcharts.Options {
        if (this.args.options.chart && typeof this.args.options.chart?.animation === 'undefined') {
            // allows the application to decide if it wants animation. handy to disable for tests
            // only disable if strictly true
            const animationDisabled1 = config.disableChartAnimation === true;
            return {
                ...this.args.options,
                chart: {
                    ...this.args.options.chart,
                    animation: !animationDisabled1
                }
            };
        }
        return this.args.options;
    }
    static{
        template(`
    <div
      {{highcharts options=this.options callback=this.setChart}}
      {{printReflow this.chart}}
      {{chartReflow this.chart}}
      ...attributes
    ></div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
