import { assert } from '@ember/debug'
import { inject as service } from '@ember/service'
import Helper from '@ember/component/helper'
import type Assets from '../services/assets.ts'

export default class AssetUrl extends Helper {
  @service()
  declare assets: Assets

  /**
   * Wrapper helper around the assetUrl util, to combine a url with the asset host
   *
   * @param source The url string to be joined to the asset host
   * @return the joined asset url string
   */
  override compute([source]: [unknown]): string {
    assert('source must be a string', typeof source === 'string')
    return this.assets.joinUrl(source).href
  }
}
