import { set } from '@ember/object'
import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'
import groupByGradePosition from '../../utils/group-by-grade-position'

/**
 * Fetches the quizzes_averages_by_grade endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The time period this request is for
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */

export default function quizScoresAverages(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('quizzes_averages_by_grade', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const summaryKey = 'quizzes_averages_summary_by_grade'
    const dataKey = 'quizzes_averages_by_grade'
    const { [summaryKey]: summary } = data

    if (summary.length === 0) {
      return null
    }

    const byGrade = groupByGradePosition(data, summaryKey, dataKey, 'lessons')

    return {
      quiz_scores_averages: byGrade.map((grade) => {
        const { lessons, grade_position } = grade
        const result = { lessons, grade_position }

        grade.summary.forEach((gradeProp) => {
          set(result, gradeProp.category, gradeProp.count || gradeProp.values)
        })

        return result
      }),
    }
  })
}
