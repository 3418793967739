import param from './param.js'
import deparam from './deparam.js'

/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
export type ParsedQuery<T> =
  | Record<string, T | T[] | null | undefined>
  | unknown

/**
 * Serialise an object into a ruby/rack style query string. Supports nested
 * objects and arrays.
 */
export function serialise(params: unknown): string {
  return param(params)
}

/**
 * Deserialise a query string in ruby/rack style query string format into an
 * object. Supports nested objects and arrays.
 */
export function deserialise(
  string: string | undefined,
): ParsedQuery<string | boolean | number> {
  if (string?.startsWith('?')) {
    string = string.slice(1)
  }

  return deparam(string)
}
