import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'

export default class TableRoute extends Route {
  queryParams = {
    sort: {
      replace: true,
    },
  }

  @service studentEvents

  @service store

  async model() {
    const product = 'maths'
    const { uiScope: scope, statsScope, period, scopedData } = this.modelFor('reporting.ui-scope')

    const stats = await this.makeStatsRequest(product, statsScope, period)

    if (stats.mentalMinuteSchoolOverview) {
      const schools = stats.mentalMinuteSchoolOverview.schools.map((school) => {
        const schoolName = this.store.peekRecord('school', String(school.school_id))?.name
        return {
          ...school,
          schoolName,
        }
      })

      stats.mentalMinuteSchoolOverview.schools = schools
    }

    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product, scope, period) {
    const { studentEvents } = this

    return RSVP.hash({
      mentalMinuteSchoolOverview: studentEvents.mentalMinuteSchoolOverview(product, scope, period),
    })
  }
}
