import Controller from '@ember/controller'
import { service } from '@ember/service'
import { action } from '@ember/object'

export default class IndexController extends Controller {
  @service router

  @action
  goToCategory(category) {
    const path = {
      lessons: 'course-progress.totals',
      // quizzes: '', // no link/report for this tile yet
      assessments: 'quizzes-completed',
      driving_tests: 'driving-tests',
      mental_minute: 'fluency-sprints.table',
    }[category]

    const { scope: scopeName, id: scopeId } = this.model.scope
    if (path) this.router.transitionTo(`reporting.ui-scope.maths.${path}`, scopeName, scopeId)
  }
}
