import Component from '@glimmer/component'

export default class Confirm extends Component {
  get duplicateStudentsInSource() {
    return this.args.candidateStudents.some((student) => student.duplicateSource)
  }

  get duplicateStudentsInDestination() {
    return this.args.candidateStudents.some((student) => student.duplicateDestination)
  }

  get duplicateStudents() {
    return this.duplicateStudentsInSource || this.duplicateStudentsInDestination
  }
}
