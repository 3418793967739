import Component from '@ember/component'
import { tagName } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { service } from '@ember/service'
import { alias } from '@ember/object/computed'
import { isPresent } from '@ember/utils'
import { themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box'

@tagName('div')
export default class DistrictMathsCharts extends Component {
  @service intl

  @service gradeSets

  @alias('data.scope.scope') uiScope

  get totalUsageBoxes() {
    const { intl } = this
    return [
      {
        category: 'lessons',
        theme: statBoxThemes.THEME_FOREST_GREEN,
      },
      {
        category: 'quizzes',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.quizzes'),
        noClickAction: true,
        theme: statBoxThemes.THEME_GRAPEY_GREEN,
      },
      {
        category: 'driving_tests',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.drivingTests'),
        theme: statBoxThemes.THEME_OCEANY_BLUE,
      },
      {
        category: 'assessments',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.assessments'),
        theme: statBoxThemes.THEME_JUICY_ORANGE,
      },
      {
        category: 'mental_minute',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.mentalMinute'),
        theme: statBoxThemes.THEME_MID_ORANGE,
      },
    ]
  }

  @computed('data.totalUsageOverTime')
  get isSummaryDataPresent() {
    return isPresent(this.data?.totalUsageOverTime)
  }

  /**
   * A mapping of driving test grades (0,1,2) to equivalent grade position names
   */
  get drivingTestGradeNames() {
    return {
      0: this.gradeSets.nameFromGradeSetByPosition(1),
      1: this.gradeSets.nameFromGradeSetByPosition(2),
      2: this.gradeSets.nameFromGradeSetByPosition(3),
    }
  }
}
