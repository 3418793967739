import { helper as buildHelper } from '@ember/component/helper'
import { capitalize } from '@ember/string'

const shortWords = [
  'of',
  'a',
  'the',
  'and',
  'an',
  'or',
  'nor',
  'but',
  'is',
  'if',
  'then',
  'else',
  'when',
  'at',
  'from',
  'by',
  'on',
  'off',
  'for',
  'in',
  'out',
  'over',
  'to',
  'into',
  'with',
]

export function titleize([string = '']) {
  const strArray = string.toString().toLowerCase().split(' ')
  const titleizedArray = strArray.map(function (word) {
    if (shortWords.includes(word)) {
      return word
    }
    return capitalize(word)
  })
  return titleizedArray.join(' ')
}

export default buildHelper(titleize)
