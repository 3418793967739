import { A } from '@ember/array'
import { Promise } from 'rsvp'
import { isEmpty } from '@ember/utils'

/**
 * Fetches the course_progress_by_grade endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {String} precinct - The precinct needed to fetch the correct data
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function courseProgressByGrade(
  product,
  precinct,
  studentScope,
  period,
) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('course_progress_by_grade', product, studentScope)
  const query = this.buildQuery(studentScope, period, { precinct })

  return this.fetchData(url, query).then((data) => {
    const {
      course_progress_by_grade: { grades: gradesOfData, student_count },
    } = data

    if (gradesOfData.length === 0) {
      return null
    }

    const filteredCourseProgressByGrade = gradesOfData.filter(
      (grade) => grade.grade_position !== null,
    )

    return {
      course_progress_by_grade: {
        grades: A(filteredCourseProgressByGrade).sortBy('grade_position'),
        student_count,
      },
    }
  })
}
