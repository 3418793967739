import Component from '@ember/component'
import { tagName } from '@ember-decorators/component'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'
import { isPresent } from '@ember/utils'
import { themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box'

@tagName('div')
export default class DistrictReCharts extends Component {
  @alias('data.scope.scope') uiScope

  get totalUsageBoxes() {
    return [
      {
        category: 'lessons',
        theme: statBoxThemes.THEME_OCEANY_BLUE,
      },
      {
        category: 'assessments',
        theme: statBoxThemes.THEME_JUICY_ORANGE,
      },
      {
        category: 'driving_tests',
        theme: statBoxThemes.THEME_STORM_BLUE,
      },
      {
        category: 'books',
        theme: statBoxThemes.THEME_PURPLY_PINK,
      },
      {
        category: 'spelling',
        theme: statBoxThemes.THEME_GRAPEY_GREEN,
      },
    ]
  }

  @computed('data.totalUsageOverTime')
  get isSummaryDataPresent() {
    return isPresent(this.data?.totalUsageOverTime)
  }
}
