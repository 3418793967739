import Component from '@glimmer/component'
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { service } from '@ember/service'
import colors from '@blakeelearning/blake-colours/colours'

/**
 * Puts together all the pieces for the quiz scores totals chart.
 *
 * @class QuizScoresTotals
 * @extends Ember.Component
 * @property {String} [grade] - The grade the chart represents.
 * @property {Object} data - The data used to render the chart
 * @property {Number} data.grade_position - The grade position
 * @property {Object[]} data.summary - The summary of the months data
 * @property {Object} data.summary[].category - The category of the summary data
 * @property {Object} data.summary[].count - The value of the summary data
 * @property {Object[]} data.months - The data for each month
 * @property {Object} data.months[].count - The number of quizzes completed this month
 * @property {Object} data.months[].month - The month this data represents
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'total_count', count: 120 },
 *     { category: 'student_count', count: 20 },
 *   ],
 *   months: [
 *     {
 *       count: 20,
 *       month: '2016-07',
 *     },
 *     {
 *       count: 100,
 *       month: '2016-08',
 *     },
 *   ],
 * ```
 */
export default class ChartsQuizScoresTotals extends Component {
  @service intl

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total quizzes completed and student counts, and average quizzes per student.
   */
  get summaryStats() {
    const summaryData = this.args?.data?.summary ?? []
    const total = summaryData.find((d) => d.category === 'total_count').count
    const studentCount = summaryData.find((d) => d.category === 'student_count').count
    const average = basicAverage(total, studentCount)

    return [
      {
        label: this.intl.t('reporting.statBoxes.assessmentScoresTotals.totals.re'),
        value: formatNumber(total, 1),
      },
      {
        label: this.intl.t('reporting.statBoxes.courseProgress.studentCount'),
        value: formatNumber(studentCount, 1),
      },
      {
        label: this.intl.t('reporting.statBoxes.assessmentScoresTotals.average.re'),
        value: formatNumber(average, 1),
      },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get columns() {
    const monthData = this.args.data?.months ?? []
    const color = colors.stormBlue300
    return [{ name: 'count', data: monthData.map((d) => d.count), color }]
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data?.months?.map((m) => convertDateFormat(m.month)) ?? []
  }
}
